import {
  LOGIN_FAIL,
  LOGIN_SUCCESS,
  USER_SUCCESS,
  USER_FAILED,
  ADMIN_SUCCESS,
  ADMIN_FAILED,
  TRANSACTION_SUCCESS,
  TRANSACTION_FAILED,
  LOGOUT,
  DETAIL_INFO,
  FEE_PROFILE_FAILED,
  FEE_PROFILE_SUCCESS,
  TRANS_CAT_FAILED,
  TRANS_CAT_SUCCESS,
  TRANS_SERVICE_CODE_SUCCESS,
  TRANS_SERVICE_CODE_FAILED,
  SET_FEE_PROFILE_FAILED,
  SET_FEE_PROFILE_SUCCESS,
  GET_BEN_SUCCESS,
  GET_BEN_FAILED,
  SET_BENEFICIARY_SUCCESS,
  SET_BENEFICIARY_FAILURE,
  GET_COMMISSION_FAILED,
  GET_COMMISSION_SUCCESS,
  GET_DETAIL_FAILED,
  GET_DETAIL_SUCCESS,
  GET_LOAN_PRODUCTS_SUCCESS,
  GET_LOAN_PRODUCTS_FAILED,
  GET_LOAN_REQUEST_SUCCESS,
  GET_LOAN_REQUEST_FAILED,
  SET_COMMISSION_FAILED,
  SET_COMMISSION_SUCCESS,
  APPROVE_LOAN_SUCCESS,
  DISBURSE_SUCCESS,
  DELETED_INFO,
  GET_TXN_LIMIT_CONFIGS_SUCCESS,
  GET_TXN_LIMIT_CONFIGS_FAILED,
  SET_TXN_LIMIT_SUCCESS,
  SET_TXN_LIMIT_FAILED,
  GET_USER_LOAN,
  GET_PARTNER_SUCCESS,
  TOGGLE_SUCCESS,
  FILTER_SUCCESS,
  SET_PROMO,
  GET_PROMO,
  DELETE_PROMO,
  TOGGLE_PROMO,
  SET_ROLE,
  GET_ROLE,
  SET_PERMISSION,
  GET_PERMISSION,
  SET_PERMISSION_MODULE,
  GET_PERMISSION_MODULE,
  SET_ADMIN_PERMISSION,
  GET_ADMIN_PERMISSION,
  DELETE_PERMISSION_LIST,
  GET_BANK_TRANS,
  GET_FLUTTER,
  DELETE_ADMIN,
  FILTER_BANK_TRANS,
  FILTER_FLUTTER,
  GET_BILL_LIST,
  GET_BILL_TRANS,
  GET_BANK_TRANS_STATUS,
  GET_FLW_RANGE,
  GET_NOTIFICATION,
  FILTER_NOTIFICATION,
  GET_CHANNEL,
  TOGGLE_CHANNEL,
  GET_NOTIFICATION_STATUS,
  GET_NOTIFICATION_TYPES,
  GET_NOTIFICATION_MODE,
  FILTER_TRANSACTION_STATUS,
  SET_REQUERY,
  SET_REVERSAL,
  ENABLE_USER,
  DISABLE_USER,
  UPDATE_ADMIN,
  FILTER_TRANSACTION,
  FILTER_USERS,
  USER_REPORT_INFO,
  TRANSACTION_REPORT,
  ACCOUNT_BALANCE,
  ACCOUNT_STATEMENT,
  NEW_RANGE,
  FILTER_USER_REPORT,
  REPORT_BY_INSTITUTE,
  GET_INSTITUTIONS,
  FILTER_TRANS_REPORT_YEAR,
  FILTER_TRANS_REPORT_INSTITUTE,
  GET_INSTITUTE_STATUS,
  FILTER_INSTITUTE_REPORT,
  GET_SERVICE_REPORT,
  GET_TRANSACTION_SERVICE,
  EDIT_PROMOTION,
  EDIT_LIMIT,
  DELETE_TRANSACTION_LIMIT,
  SERVICE_APPLICABLE,
  GET_SUBSCRIBERS,
  GET_PROMO_SUBSCRIBERS,
  ADD_BATCH,
  GET_BATCH,
  DELETE_BATCH,
  GET_DOCUMENT,
  SET_LEVEL,
  GET_LEVEL,
  SET_LEVEL_SETTINGS,
  GET_LEVEL_SETTINGS,
  SET_REQUIREMENT,
  GET_REQUIREMENT,
  APPROVE_DOCUMENT,
  REJECT_DOCUMENT,
  TRANSACTION_DETAIL_INFO,
  LOAD_HISTORY,
  LOAN_DETAILS,
  KYC_LEVEL_DETAIL,
  DELETE_KYC_LEVEL,
  AUDIT_LOGS_SUCCESS,
  AUDIT_LOGS_FAILED,
  EDIT_KYC_LEVEL,
  FINAL_APPROVAL,
  DELETE_REQUIREMENT,
  SET_RATE,
  GET_RATE,
  SET_KYC_VIRTUAL_CARD,
  GET_KYC_VIRTUAL_CARD,
  SET_VIRTUAL_LIMIT,
  GET_VIRTUAL_LIMIT,
  GET_VIRTUAL_TRANS,
  GET_VIRTUAL_CARD,
  GET_CURRENCIES,
  SWITCH_BILL,
  LOCK_CARD,
  UNLOCK_CARD,
  GET_CARD_REQUEST,
  SET_BATCH_REQUEST,
  GET_BATCH_REQUEST,
  GET_A_RATE,
  FILTER_DOCUMENT,
  DEVELOPER_ACCOUNTS_SUCCESS,
  DEVELOPER_ACCOUNTS_FAILED,
  DEVELOPER_CALLS_SUCCESS,
  DEVELOPER_CALLS_FAILED,
  DEVELOPER_CALL_STATS_SUCCESS,
  DEVELOPER_CALL_STATS_FAILED,
  ADD_USER_BY_AGENT,
  ADD_USER_BY_AGENT_FAIL,
  UPLOAD_USER_DOCS,
  ADD_AGENT_CLAIM,
} from "./action.types";
import {
  loginAdmin,
  fetchAdmin,
  fetchUsers,
  fetchTransaction,
  fetchAllFees,
  fetchLimitConfigs,
  getTransCat,
  getService,
  setFeeProfile,
  getBeneficiary,
  setBeneficiary,
  fetchCommission,
  getFullDetails,
  getNewDetails,
  getLoanProducts,
  getLoanRequests,
  setCommission,
  changeStatus,
  disburseLoan,
  deleteFeeSplit,
  userLoanDetails,
  getPartners,
  _toggleCommissionProfile,
  filterBy,
  setPromotion,
  fetchAllPromotion,
  deletePromo,
  togglePromotion,
  setRole,
  getRole,
  setpermissionRequest,
  getpermissionRequest,
  setPermissionModule,
  getPermissionModule,
  setAdminPermission,
  getAllAdminPermission,
  deleteAuthPermission,
  bankTransactRequest,
  flutterwaveRequest,
  deleteAdminUser,
  filterBankTransact,
  filterFlutterwave,
  getBillListRequest,
  getBillTransactionReq,
  filterBankByStatus,
  filterFlutterRange,
  getAllNotification,
  filterAllNotification,
  getChannel,
  toggleChannel,
  notificationValue,
  filterTransactionStatus,
  sendRequery,
  setReversal,
  lockUser,
  editAdmin,
  filterTransaction,
  filterUsers,
  UserReportRequest,
  TransactionReportRequest,
  getBal,
  getStatement,
  filterUserReportRequest,
  getInstitution,
  InstitutionReport,
  getTransactionService,
  editGetriPromotion,
  editTransactionLimitConfig,
  deleteTransactionLimit,
  fetchAllSubscriptions,
  fetchPromotionSubscribers,
  addCardBatch,
  fetchAllBatches,
  removeBatch,
  getKycDocument,
  addKycLevel,
  fetchKycLevel,
  setLevelSetting,
  getLevelSetting,
  setRequirement,
  getRequirement,
  ApproveDocument,
  getUserLoginHistory,
  kycLevel,
  deleteKyc,
  fetchAuditLogs,
  editKycLevel,
  finalApproval,
  deleteRequirement,
  CardRateRequest,
  fetchCardRateRequest,
  addKycVirtualCard,
  fetchKycVirtualCard,
  setVirtualCardConfig,
  getVirtualCardConfig,
  getRateCurrency,
  switchBill,
  changeCardStatus,
  addCardRequestBatch,
  _fetchRate,
  filterDocumentByStatus,
  fetchDeveloperAccounts,
  fetchDeveloperApiCalls,
  fetchDeveloperCallStats,
  agentQuery,
  _agentUploadUserDoc,
} from "../../API";
import { fetchCardRequestBatch } from "../../API/CardRequest";

export const adminLogin = (mData) => async (dispatch) => {
  dispatch({ type: "LOADING" });
  try {
    const { success, token, permission, data } = await loginAdmin(mData);
    dispatch({
      type: LOGIN_SUCCESS,
      payload: { success, token, permission, data },
    });
  } catch (error) {
    alert(error);
    dispatch({ type: LOGIN_FAIL, payload: error });
  }
};

export const getUsers = (token, pageNum) => async (dispatch) => {
  dispatch({ type: "SENDING" });
  try {
    const { success, users, total } = await fetchUsers(token, pageNum);
    dispatch({ type: USER_SUCCESS, payload: { success, users, total } });
  } catch (error) {
    dispatch({ type: USER_FAILED, payload: error });
  }
};

export const getAdmins = (token) => async (dispatch) => {
  dispatch({ type: "SENDING" });
  try {
    const { admin_info, success, total } = await fetchAdmin(token);
    console.log(admin_info);
    dispatch({ type: ADMIN_SUCCESS, payload: { success, admin_info, total } });
  } catch (error) {
    dispatch({ type: ADMIN_FAILED, payload: error });
  }
};

export const getTransaction = (page, token) => async (dispatch) => {
  dispatch({ type: "SENDING" });
  try {
    const { success, transaction, allTransactions } = await fetchTransaction(
      page,
      token
    );
    dispatch({
      type: TRANSACTION_SUCCESS,
      payload: { success, transaction, allTransactions },
    });
  } catch (error) {
    dispatch({ type: TRANSACTION_FAILED, payload: error });
  }
};

export const getFeeProfile = (id, token) => async (dispatch) => {
  dispatch({ type: "SENDING" });
  try {
    const { success, mFeeProfiles, allFeeProfiles } = await fetchAllFees(
      id,
      token
    );
    console.log("all ", mFeeProfiles);
    dispatch({
      type: FEE_PROFILE_SUCCESS,
      payload: { success, mFeeProfiles, allFeeProfiles },
    });
  } catch (error) {
    console.log(error);
    dispatch({ type: FEE_PROFILE_FAILED, payload: error });
  }
};

export const setFee = (data, token) => async (dispatch) => {
  dispatch({ type: "ISLOADING" });
  try {
    const { response_description, success } = await setFeeProfile(data, token);
    dispatch({
      type: SET_FEE_PROFILE_SUCCESS,
      payload: { response_description, success },
    });
  } catch (error) {
    dispatch({ type: SET_FEE_PROFILE_FAILED, payload: error });
  }
};

export const getLimitConfigs = (id, token) => async (dispatch) => {
  dispatch({ type: "SENDING" });
  try {
    const { mLimitConfigs, allLimitConfigs } = await fetchLimitConfigs(
      id,
      token
    );
    console.log("all ", mLimitConfigs);
    dispatch({
      type: GET_TXN_LIMIT_CONFIGS_SUCCESS,
      payload: { mLimitConfigs, allLimitConfigs },
    });
  } catch (error) {
    console.log(error);
    dispatch({ type: GET_TXN_LIMIT_CONFIGS_FAILED, payload: error });
  }
};

export const getTransCategory = (token) => async (dispatch) => {
  dispatch({ type: "LOADING" });
  try {
    const transactionCategory = await getTransCat(token);
    dispatch({ type: TRANS_CAT_SUCCESS, payload: { transactionCategory } });
  } catch (error) {
    dispatch({ type: TRANS_CAT_FAILED, payload: error });
  }
};

export const getServiceCode = (id, token) => async (dispatch) => {
  // dispatch({ type: "LOADING" });
  try {
    const transactionServiceCode = await getService(id, token);
    dispatch({
      type: TRANS_SERVICE_CODE_SUCCESS,
      payload: { transactionServiceCode },
    });
  } catch (error) {
    dispatch({ type: TRANS_SERVICE_CODE_FAILED, payload: error });
  }
};

export const getServiceApplicable = (id, token) => async (dispatch) => {
  try {
    const serviceApplicable = await getService(id, token);
    dispatch({ type: SERVICE_APPLICABLE, payload: { serviceApplicable } });
  } catch (error) {}
};

export const getBeneficiaries = (token) => async (dispatch) => {
  // dispatch({ type: "LOADING" });
  try {
    const beneficiaryDetails = await getBeneficiary(token);
    console.log("all beneficiaries", beneficiaryDetails);
    dispatch({ type: GET_BEN_SUCCESS, payload: { beneficiaryDetails } });
  } catch (error) {
    dispatch({ type: GET_BEN_FAILED, payload: error });
  }
};

// Get loan products
export const getAllLoanProducts = (token) => async (dispatch) => {
  try {
    const { success, allLoanProducts } = await getLoanProducts(token);
    console.log("all loan products", allLoanProducts);
    dispatch({
      type: GET_LOAN_PRODUCTS_SUCCESS,
      payload: { success, allLoanProducts },
    });
  } catch (error) {
    dispatch({ type: GET_LOAN_PRODUCTS_FAILED, payload: error });
  }
};

export const getAllLoanRequests = (token) => async (dispatch) => {
  try {
    const { success, allLoanRequests } = await getLoanRequests(token);
    console.log("all loan products", allLoanRequests);
    dispatch({
      type: GET_LOAN_REQUEST_SUCCESS,
      payload: { success, allLoanRequests },
    });
  } catch (error) {
    dispatch({ type: GET_LOAN_REQUEST_FAILED, payload: error });
  }
};

export const addBeneficiary = (data, token) => async (dispatch) => {
  dispatch({ type: "LOADING" });
  try {
    const { success } = await setBeneficiary(data, token);
    dispatch({ type: SET_BENEFICIARY_SUCCESS, payload: { success } });
  } catch (error) {
    dispatch({ type: SET_BENEFICIARY_FAILURE, payload: error });
  }
};

export const getCommission = (token) => async (dispatch) => {
  dispatch({ type: "LOADING" });
  try {
    const { commissionProfiles } = await fetchCommission(token);
    dispatch({ type: GET_COMMISSION_SUCCESS, payload: { commissionProfiles } });
  } catch (error) {
    dispatch({ type: GET_COMMISSION_FAILED, payload: error });
  }
};

export const setCommissionProfile = (data, token) => async (dispatch) => {
  dispatch({ type: "LOADING" });
  try {
    const { response_description, success } = await setCommission(data, token);
    dispatch({
      type: SET_COMMISSION_SUCCESS,
      payload: { response_description, success },
    });
  } catch (error) {
    dispatch({ type: SET_COMMISSION_FAILED, payload: error });
  }
};

export const logOut = () => ({
  type: LOGOUT,
  payload: true,
});

export const AppStart = () => ({
  type: "APP_START",
  payload: false,
});

export const detailsInfo = (id, is_transaction, token) => async (dispatch) => {
  dispatch({ type: "LOADING" });
  try {
    const { result } = await getFullDetails(id, token);
    if (is_transaction == "transaction") {
      dispatch({ type: TRANSACTION_DETAIL_INFO, payload: { result } });
    } else if (is_transaction == "loan_request") {
      dispatch({ type: LOAN_DETAILS, payload: { result } });
    } else {
      dispatch({ type: GET_DETAIL_SUCCESS, payload: { result } });
    }
  } catch (error) {
    dispatch({ type: GET_DETAIL_FAILED, payload: error });
  }
};

export const approveLoan = (id, token) => async (dispatch) => {
  dispatch({ type: "LOADING" });
  try {
    const { result } = await changeStatus(id, token);
    dispatch({ type: APPROVE_LOAN_SUCCESS, payload: { result } });
  } catch (error) {}
};

export const loanDisburse = (id, token) => async (dispatch) => {
  dispatch({ type: "LOADING" });
  try {
    const { result } = await disburseLoan(id, token);
    console.log("wasa");
    dispatch({ type: DISBURSE_SUCCESS, payload: { result } });
  } catch (error) {}
};

export const loanTabDetails = (url, token) => async (dispatch) => {
  try {
    const { result } = await userLoanDetails(url, token);
    // const { success, data } = await result;
    dispatch({ type: GET_USER_LOAN, payload: { result } });
  } catch (error) {}
};

export const tabDetail = (url, token) => async (dispatch) => {
  try {
    const { result } = await getFullDetails(url, token);
    console.log("all ", result);
    dispatch({ type: DETAIL_INFO, payload: { result } });
  } catch (error) {
    // dispatch({ type: GET_DETAIL_FAILED, payload: error });
  }
};

export const newTabDetail = (url, token) => async (dispatch) => {
  try {
    const { result } = await getNewDetails(url, token);
    console.log("all deet n ", result);
    dispatch({ type: NEW_RANGE, payload: { result } });
  } catch (error) {
    // dispatch({ type: GET_DETAIL_FAILED, payload: error });
  }
};

export const deleteSplit = (url, data, token) => async (dispatch) => {
  try {
    const { result } = await deleteFeeSplit(url, data, token);
    dispatch({ type: DELETED_INFO, payload: { result } });
  } catch (error) {}
};
export const _getPartners = (token) => async (dispatch) => {
  try {
    const { servicePartners } = await getPartners(token);
    dispatch({ type: GET_PARTNER_SUCCESS, payload: { servicePartners } });
  } catch (error) {}
};

export const toggleCommProf = (id, token) => async (dispatch) => {
  try {
    const success = await _toggleCommissionProfile(id, token);
    dispatch({ type: TOGGLE_SUCCESS, payload: success });
  } catch (error) {}
};

export const filterUserTable = (data, page, token) => async (dispatch) => {
  dispatch({ type: "SENDING" });
  try {
    const { users, success, total } = await filterBy(data, page, token);
    dispatch({ type: FILTER_SUCCESS, payload: { users, success, total } });
  } catch (error) {}
};

export const sendPromotion = (data, token) => async (dispatch) => {
  dispatch({ type: "LOADING" });
  try {
    const { success, promotion, response_description } = await setPromotion(
      data,
      token
    );
    dispatch({
      type: SET_PROMO,
      payload: { success, promotion, response_description },
    });
  } catch (error) {}
};

export const getAllPromotions = (token) => async (dispatch) => {
  dispatch({ type: "PROMO_LOADING" });
  try {
    const { promoConfigs } = await fetchAllPromotion(token);
    dispatch({ type: GET_PROMO, payload: promoConfigs });
  } catch (error) {}
};

export const deletePromotion = (id, token) => async (dispatch) => {
  try {
    const { success, response_description } = await deletePromo(id, token);
    dispatch({
      type: DELETE_PROMO,
      payload: { success, response_description },
    });
  } catch (error) {}
};
export const togglePromo = (id, token) => async (dispatch) => {
  try {
    const { success, response_description } = await togglePromotion(id, token);
    dispatch({
      type: TOGGLE_PROMO,
      payload: { success, response_description },
    });
  } catch (error) {}
};
export const setAdminRole = (mData, token) => async (dispatch) => {
  try {
    const { success, data, response_message } = await setRole(mData, token);
    dispatch({ type: SET_ROLE, payload: { success, data, response_message } });
  } catch (error) {}
};

export const getAdminRole = (token) => async (dispatch) => {
  try {
    const { success, data, response_message } = await getRole(token);
    dispatch({ type: GET_ROLE, payload: { success, data, response_message } });
  } catch (error) {}
};

export const setPermission = (mData, token) => async (dispatch) => {
  try {
    const { success, response_message } = await setpermissionRequest(
      mData,
      token
    );
    dispatch({ type: SET_PERMISSION, payload: { success, response_message } });
  } catch (error) {}
};

export const getPermission = (token) => async (dispatch) => {
  try {
    const { success, data } = await getpermissionRequest(token);
    dispatch({ type: GET_PERMISSION, payload: { success, data } });
  } catch (error) {}
};

export const setPermissionMod = (mData, token) => async (dispatch) => {
  try {
    const { success, response_message } = await setPermissionModule(
      mData,
      token
    );
    dispatch({
      type: SET_PERMISSION_MODULE,
      payload: { success, response_message },
    });
  } catch (error) {}
};

export const getPermissionMod = (token) => async (dispatch) => {
  try {
    const { success, data, response_message } = await getPermissionModule(
      token
    );
    dispatch({
      type: GET_PERMISSION_MODULE,
      payload: { success, data, response_message },
    });
  } catch (error) {}
};

export const sendAdminPermission = (data, token) => async (dispatch) => {
  try {
    const { success, response_message } = await setAdminPermission(data, token);
    dispatch({
      type: SET_ADMIN_PERMISSION,
      payload: { success, response_message },
    });
  } catch (error) {}
};

export const getAdminPermission = (token) => async (dispatch) => {
  try {
    const { success, data, response_message } = await getAllAdminPermission(
      token
    );
    dispatch({
      type: GET_ADMIN_PERMISSION,
      payload: { success, data, response_message },
    });
  } catch (error) {}
};

export const deletePermission = (url, token) => async (dispatch) => {
  try {
    const result = await deleteAuthPermission(url, token);
    dispatch({ type: DELETE_PERMISSION_LIST, payload: { result } });
  } catch (error) {}
};

export const getBankTransactionService = (url, token) => async (dispatch) => {
  dispatch({ type: "DATA_LOADING" });
  try {
    const { data, total } = await bankTransactRequest(url, token);
    dispatch({ type: GET_BANK_TRANS, payload: { data, total } });
  } catch (error) {}
};

export const getFlutterwaveService = (url, token) => async (dispatch) => {
  dispatch({ type: "DATA_LOADING" });
  try {
    const { data, total } = await flutterwaveRequest(url, token);
    dispatch({ type: GET_FLUTTER, payload: { data, total } });
  } catch (error) {}
};

export const deleteAdminAction = (token, id) => async (dispatch) => {
  try {
    const result = await deleteAdminUser(token, id);
    dispatch({ type: DELETE_ADMIN, payload: { result } });
  } catch (error) {}
};

export const filterTransactions = (mdata, token) => async (dispatch) => {
  dispatch({ type: "DATA_LOADING" });
  try {
    const { data, total } = await filterBankTransact(mdata, token);
    dispatch({ type: FILTER_BANK_TRANS, payload: { data, total } });
  } catch (error) {}
};

export const filterFlutter = (mData, token) => async (dispatch) => {
  dispatch({ type: "DATA_LOADING" });
  try {
    const { data, total } = await filterFlutterwave(mData, token);
    dispatch({ type: FILTER_FLUTTER, payload: { data, total } });
  } catch (error) {}
};

export const getBillerList = (token) => async (dispatch) => {
  dispatch({ type: "LOADING" });
  try {
    const { result } = await getBillListRequest(token);
    dispatch({ type: GET_BILL_LIST, payload: { result } });
  } catch (error) {}
};

export const getBillTransactions = (per_page, token) => async (dispatch) => {
  dispatch({ type: "LOADING" });
  try {
    const { transactions, allTransactions } = await getBillTransactionReq(
      per_page,
      token
    );
    dispatch({
      type: GET_BILL_TRANS,
      payload: { transactions, allTransactions },
    });
  } catch (error) {}
};
export const filterBankTranStatus = (startDate, endDate, token) => async (
  dispatch
) => {
  dispatch({ type: "DATA_LOADING" });
  try {
    const { data, total } = await filterBankByStatus(startDate, endDate, token);
    dispatch({ type: GET_BANK_TRANS_STATUS, payload: { data, total } });
  } catch (error) {}
};

export const filterFlutterDateRange = (startDate, endDate, token) => async (
  dispatch
) => {
  dispatch({ type: "DATA_LOADING" });
  try {
    const { data, total } = await filterFlutterRange(startDate, endDate, token);
    dispatch({ type: GET_FLW_RANGE, payload: { data, total } });
  } catch (error) {}
};

export const getNotification = (token) => async (dispatch) => {
  dispatch({ type: "LOADING" });
  try {
    const {
      notifications,
      response_description,
      allNotifications,
    } = await getAllNotification(token);
    dispatch({
      type: GET_NOTIFICATION,
      payload: { notifications, allNotifications, response_description },
    });
  } catch (error) {}
};

export const filterNotification = (mData, token) => async (dispatch) => {
  dispatch({ type: "LOADING" });
  try {
    const {
      notifications,
      response_description,
      allNotifications,
    } = await filterAllNotification(mData, token);
    dispatch({
      type: FILTER_NOTIFICATION,
      payload: { notifications, response_description, allNotifications },
    });
  } catch (error) {}
};

export const getNotificationChannels = (token) => async (dispatch) => {
  dispatch({ type: "LOADING" });
  try {
    const { channels } = await getChannel(token);
    dispatch({ type: GET_CHANNEL, payload: { channels } });
  } catch (error) {}
};

export const toggleNotificationChannel = (id, token) => async (dispatch) => {
  try {
    const { success, response_description } = await toggleChannel(id, token);
    dispatch({
      type: TOGGLE_CHANNEL,
      payload: { success, response_description },
    });
  } catch (error) {}
};

export const getNotificationStatus = (url, token) => async (dispatch) => {
  try {
    const { data } = await notificationValue(url, token);
    dispatch({ type: GET_NOTIFICATION_STATUS, payload: { data } });
  } catch (error) {}
};

export const getNotificationTypes = (url, token) => async (dispatch) => {
  try {
    const { data } = await notificationValue(url, token);
    dispatch({ type: GET_NOTIFICATION_TYPES, payload: { data } });
  } catch (error) {}
};

export const getNotificationMode = (url, token) => async (dispatch) => {
  try {
    const { data } = await notificationValue(url, token);
    dispatch({ type: GET_NOTIFICATION_MODE, payload: { data } });
  } catch (error) {}
};

export const filterStatus = (status, page, token) => async (dispatch) => {
  dispatch({ type: "SENDING" });
  try {
    const {
      success,
      transaction,
      allTransactions,
    } = await filterTransactionStatus(status, page, token);
    dispatch({
      type: FILTER_TRANSACTION_STATUS,
      payload: { success, transaction, allTransactions },
    });
  } catch (error) {
    // dispatch({ type: TRANSACTION_FAILED, payload: error });
  }
};

export const reQuery = (admin_id, trans_ref, token) => async (dispatch) => {
  try {
    const { data, response_message } = await sendRequery(
      admin_id,
      trans_ref,
      token
    );
    dispatch({ type: SET_REQUERY, payload: { data, response_message } });
  } catch (error) {}
};

export const reverseTransaction = (admin_id, trans_id, token) => async (
  dispatch
) => {
  try {
    const { data, response_message } = await setReversal(
      admin_id,
      trans_id,
      token
    );
    dispatch({ type: SET_REVERSAL, payload: { data, response_message } });
  } catch (error) {}
};

export const enableLock = (url, token) => async (dispatch) => {
  try {
    const { success, Response_message } = await lockUser(url, token);
    dispatch({
      type: ENABLE_USER,
      payload: { success, Response_message },
    });
  } catch (error) {}
};

export const disableLock = (url, token) => async (dispatch) => {
  try {
    const { success, Response_message } = await lockUser(url, token);
    dispatch({ type: DISABLE_USER, payload: { success, Response_message } });
  } catch (error) {}
};

export const editAdminInfo = (id, data, token) => async (dispatch) => {
  try {
    const { success, response_message } = await editAdmin(id, data, token);
    dispatch({ type: UPDATE_ADMIN, payload: { success, response_message } });
  } catch (error) {}
};

export const filterAllTransaction = (data, token) => async (dispatch) => {
  try {
    const { transaction, allTransactions, success } = await filterTransaction(
      data,
      token
    );
    dispatch({
      type: FILTER_TRANSACTION,
      payload: { transaction, allTransactions, success },
    });
  } catch (error) {}
};

export const filterAllUsers = (data, token) => async (dispatch) => {
  try {
    const { success, users, total } = await filterUsers(data, token);
    dispatch({ type: FILTER_USERS, payload: { success, users, total } });
  } catch (error) {}
};

export const getUserReport = (token) => async (dispatch) => {
  dispatch({ type: "LOADING" });
  try {
    const { data } = await UserReportRequest(token);
    dispatch({ type: USER_REPORT_INFO, payload: { data } });
  } catch (error) {}
};

export const filterReport = (year, token) => async (dispatch) => {
  dispatch({ type: "LOADING" });
  try {
    const { data } = await filterUserReportRequest(year, token);
    dispatch({ type: FILTER_USER_REPORT, payload: { data } });
  } catch (error) {}
};

export const getTransactionReport = (url, token) => async (dispatch) => {
  dispatch({ type: "LOADING" });
  try {
    const { transactionsArr } = await TransactionReportRequest(url, token);
    dispatch({ type: TRANSACTION_REPORT, payload: { transactionsArr } });
  } catch (error) {}
};

export const getTransactionByInstitute = (url, token) => async (dispatch) => {
  dispatch({ type: "LOADING" });
  try {
    const { transactionsArr } = await TransactionReportRequest(url, token);
    dispatch({ type: REPORT_BY_INSTITUTE, payload: { transactionsArr } });
  } catch (error) {}
};

export const filterByYear = (url, token) => async (dispatch) => {
  dispatch({ type: "LOADING" });
  try {
    const { data } = await TransactionReportRequest(url, token);
    dispatch({ type: FILTER_TRANS_REPORT_YEAR, payload: { data } });
  } catch (error) {}
};

export const filterByInstitution = (url, token) => async (dispatch) => {
  dispatch({ type: "LOADING" });
  try {
    const { data } = await TransactionReportRequest(url, token);
    dispatch({ type: FILTER_TRANS_REPORT_INSTITUTE, payload: { data } });
  } catch (error) {}
};

// ACCOUNT BALANCE
export const accountBalance = (number, token) => async (dispatch) => {
  try {
    const { result } = await getBal(number, token);
    dispatch({ type: ACCOUNT_BALANCE, payload: { result } });
  } catch (error) {}
};

// ACCOUNT STATEMENT
export const accountStatement = (begin, stop, acc, token) => async (
  dispatch
) => {
  try {
    const { result } = await getStatement(begin, stop, acc, token);
    dispatch({ type: ACCOUNT_STATEMENT, payload: { result } });
  } catch (error) {}
};

export const getInstitutions = (token) => async (dispatch) => {
  try {
    const { recipientInstitutionNames } = await getInstitution(token);
    dispatch({
      type: GET_INSTITUTIONS,
      payload: { recipientInstitutionNames },
    });
  } catch (error) {}
};

export const instituteReport = (url, token) => async (dispatch) => {
  dispatch({ type: "LOADING" });
  try {
    const { transactionsArr } = await InstitutionReport(url, token);
    dispatch({ type: GET_INSTITUTE_STATUS, payload: { transactionsArr } });
  } catch (error) {}
};

export const serviceReport = (url, token) => async (dispatch) => {
  dispatch({ type: "LOADING" });
  try {
    const { transactionsArr } = await InstitutionReport(url, token);
    dispatch({ type: GET_SERVICE_REPORT, payload: { transactionsArr } });
  } catch (error) {}
};

export const filterInstitutionReport = (url, token) => async (dispatch) => {
  dispatch({ type: "LOADING" });
  try {
    const { transactionsArr } = await InstitutionReport(url, token);
    dispatch({ type: FILTER_INSTITUTE_REPORT, payload: { transactionsArr } });
  } catch (error) {}
};

export const getTransactServices = (token) => async (dispatch) => {
  try {
    const { service } = await getTransactionService(token);
    dispatch({ type: GET_TRANSACTION_SERVICE, payload: { service } });
  } catch (error) {}
};

export const editPromotion = (id, data, token) => async (dispatch) => {
  try {
    const { response_description, success } = await editGetriPromotion(
      id,
      data,
      token
    );
    dispatch({
      type: EDIT_PROMOTION,
      payload: { response_description, success },
    });
  } catch (error) {}
};

export const updateTransactionLimit = (id, data, token) => async (dispatch) => {
  try {
    const { response_description } = await editTransactionLimitConfig(
      id,
      data,
      token
    );
    dispatch({ type: EDIT_LIMIT, payload: { response_description } });
  } catch (error) {}
};

export const deleteTransLimit = (id, token) => async (dispatch) => {
  try {
    const { response_description } = await deleteTransactionLimit(id, token);
    dispatch({
      type: DELETE_TRANSACTION_LIMIT,
      payload: { response_description },
    });
  } catch (error) {}
};

export const getAllPromotionSub = (token) => async (dispatch) => {
  dispatch({ type: "SUB_LOADING" });
  try {
    const { allPromoSubscriptions } = await fetchAllSubscriptions(token);
    dispatch({ type: GET_SUBSCRIBERS, payload: { allPromoSubscriptions } });
  } catch (error) {}
};

export const getPromotionSubscriber = (serviceApplicable, token) => async (
  dispatch
) => {
  dispatch({ type: "SUB_LOADING" });
  try {
    const { allPromoSubscriptions } = await fetchPromotionSubscribers(
      serviceApplicable,
      token
    );
    dispatch({
      type: GET_PROMO_SUBSCRIBERS,
      payload: { allPromoSubscriptions },
    });
  } catch (error) {}
};

export const addBatch = (mData, token) => async (dispatch) => {
  try {
    const { success, data, message } = await addCardBatch(mData, token);
    dispatch({ type: ADD_BATCH, payload: { success, data, message } });
  } catch (error) {}
};

export const getAllCardBatch = (url, token) => async (dispatch) => {
  dispatch({ type: "LOADING" });
  try {
    const { data } = await fetchAllBatches(url, token);
    dispatch({ type: GET_BATCH, payload: { data } });
  } catch (error) {}
};
export const getAllCardRequest = (url, token) => async (dispatch) => {
  dispatch({ type: "LOADING" });
  try {
    const { data } = await fetchAllBatches(url, token);
    dispatch({ type: GET_CARD_REQUEST, payload: { data } });
  } catch (error) {}
};

export const deleteBatch = (id, url, token) => async (dispatch) => {
  try {
    const { Response_message } = await removeBatch(id, url, token);
    dispatch({ type: DELETE_BATCH, payload: { Response_message } });
  } catch (error) {}
};

export const setBatchRequest = (mdata, token) => async (dispatch) => {
  try {
    const { data } = await addCardRequestBatch(mdata, token);
    dispatch({ type: SET_BATCH_REQUEST, payload: { data } });
  } catch (error) {}
};

export const getBatchRequest = (token) => async (dispatch) => {
  dispatch({ type: "LOADING" });
  try {
    const { data } = await fetchCardRequestBatch(token);
    dispatch({ type: GET_BATCH_REQUEST, payload: { data } });
  } catch (error) {}
};

export const getKycDocuments = (token) => async (dispatch) => {
  dispatch({ type: "LOADING" });
  try {
    const { data } = await getKycDocument(token);
    dispatch({ type: GET_DOCUMENT, payload: { data } });
  } catch (error) {}
};

export const setKycLevel = (data, token) => async (dispatch) => {
  try {
    const { result } = await addKycLevel(data, token);
    dispatch({ type: SET_LEVEL, payload: { result } });
  } catch (error) {}
};

export const getKycLevel = (token) => async (dispatch) => {
  dispatch({ type: "LOADING" });
  try {
    const { data } = await fetchKycLevel(token);
    dispatch({ type: GET_LEVEL, payload: { data } });
  } catch (error) {}
};

export const setKycLevelSettings = (data, token) => async (dispatch) => {
  try {
    const { response_message } = await setLevelSetting(data, token);
    dispatch({ type: SET_LEVEL_SETTINGS, payload: { response_message } });
  } catch (error) {}
};

export const getKycLevelSettings = (token) => async (dispatch) => {
  dispatch({ type: "LOADING" });
  try {
    const { data } = await getLevelSetting(token);
    dispatch({ type: GET_LEVEL_SETTINGS, payload: { data } });
  } catch (error) {}
};

export const setKycRequirement = (data, token) => async (dispatch) => {
  try {
    const { response_message } = await setRequirement(data, token);
    dispatch({ type: SET_REQUIREMENT, payload: { response_message } });
  } catch (error) {}
};

export const getKycRequirement = (token) => async (dispatch) => {
  dispatch({ type: "LOADING" });
  try {
    const { data } = await getRequirement(token);
    dispatch({ type: GET_REQUIREMENT, payload: { data } });
  } catch (error) {}
};

export const approveKyc = (mData, url, token) => async (dispatch) => {
  try {
    const { response_message } = await ApproveDocument(mData, url, token);
    dispatch({ type: APPROVE_DOCUMENT, payload: { response_message } });
  } catch (error) {}
};

export const rejectKyc = (mData, url, token) => async (dispatch) => {
  try {
    const { response_message } = await ApproveDocument(mData, url, token);
    dispatch({ type: REJECT_DOCUMENT, payload: { response_message } });
  } catch (error) {}
};

export const loadHistory = (id, token) => async (dispatch) => {
  try {
    const { result } = await getUserLoginHistory(id, token);
    dispatch({ type: LOAD_HISTORY, payload: { result } });
  } catch (error) {}
};

export const getKycLevelDetail = (url, token) => async (dispatch) => {
  dispatch({ type: "DETAIL_LOADING" });
  try {
    const { data } = await kycLevel(url, token);
    dispatch({ type: KYC_LEVEL_DETAIL, payload: { data } });
  } catch (error) {}
};

export const deleteKycLevel = (url, token) => async (dispatch) => {
  try {
    const { Response_message } = await deleteKyc(url, token);
    dispatch({ type: DELETE_KYC_LEVEL, payload: { Response_message } });
  } catch (error) {}
};

export const updateKycLevel = (url, data, token) => async (dispatch) => {
  try {
    const { response_message } = await editKycLevel(url, data, token);
    dispatch({ type: EDIT_KYC_LEVEL, payload: { response_message } });
  } catch (error) {}
};

export const giveFinalApproval = (data, url, token) => async (dispatch) => {
  try {
    const { response_message } = await finalApproval(data, url, token);
    dispatch({ type: FINAL_APPROVAL, payload: { response_message } });
  } catch (error) {}
};

export const getAuditLogs = (word, token) => async (dispatch) => {
  dispatch({ type: "SENDING" });
  try {
    const { data, success } = await fetchAuditLogs(word, token);
    dispatch({ type: AUDIT_LOGS_SUCCESS, payload: { success, data } });
  } catch (error) {
    dispatch({ type: AUDIT_LOGS_FAILED, payload: error });
  }
};

export const deleteKycRequirement = (id, token) => async (dispatch) => {
  try {
    const { Response_message } = await deleteRequirement(id, token);
    dispatch({ type: DELETE_REQUIREMENT, payload: { Response_message } });
  } catch (error) {}
};

export const addCardRate = (data, token) => async (dispatch) => {
  try {
    const { response_message } = await CardRateRequest(data, token);
    dispatch({ type: SET_RATE, payload: { response_message } });
  } catch (error) {}
};

export const getCardRate = (token) => async (dispatch) => {
  dispatch({ type: "LOADING" });
  try {
    const { rate_data } = await fetchCardRateRequest(token);
    dispatch({ type: GET_RATE, payload: { rate_data } });
  } catch (error) {}
};
export const setKycVirtualCard = (data, token) => async (dispatch) => {
  try {
    const { response_message } = await addKycVirtualCard(data, token);
    dispatch({ type: SET_KYC_VIRTUAL_CARD, payload: { response_message } });
  } catch (error) {}
};
export const getKycVirtualCard = (token) => async (dispatch) => {
  dispatch({ type: "LOADING" });
  try {
    const { data } = await fetchKycVirtualCard(token);
    dispatch({ type: GET_KYC_VIRTUAL_CARD, payload: { data } });
  } catch (error) {}
};

export const setVirtualTransactionLimit = (data, url, token) => async (
  dispatch
) => {
  try {
    const { response_message } = await setVirtualCardConfig(data, url, token);
    dispatch({ type: SET_VIRTUAL_LIMIT, payload: { response_message } });
  } catch (error) {}
};
export const getVirtualTransactionLimit = (url, limit, token) => async (
  dispatch
) => {
  dispatch({ type: "LOADING" });
  try {
    const { data } = await getVirtualCardConfig(url, limit, token);
    dispatch({ type: GET_VIRTUAL_LIMIT, payload: { data } });
  } catch (error) {}
};

export const getVirtualTransaction = (url, token) => async (dispatch) => {
  dispatch({ type: "LOADING" });
  try {
    const { card_data } = await getVirtualCardConfig(url, token);
    dispatch({ type: GET_VIRTUAL_TRANS, payload: { card_data } });
  } catch (error) {}
};

export const getVirtualCards = (url, token) => async (dispatch) => {
  dispatch({ type: "LOADING" });
  try {
    const { card_data } = await getVirtualCardConfig(url, token);
    dispatch({ type: GET_VIRTUAL_CARD, payload: { card_data } });
  } catch (error) {}
};

export const getCurrencies = (token) => async (dispatch) => {
  try {
    const { data } = await getRateCurrency(token);
    dispatch({ type: GET_CURRENCIES, payload: { data } });
  } catch (error) {}
};

export const switchBiller = (billname, token) => async (dispatch) => {
  try {
    const { message } = await switchBill(billname, token);
    dispatch({ type: SWITCH_BILL, payload: { message } });
  } catch (error) {}
};

export const lockCard = (mdata, url, token) => async (dispatch) => {
  try {
    const { response_message, data } = await changeCardStatus(
      mdata,
      url,
      token
    );
    dispatch({ type: LOCK_CARD, payload: { response_message, data } });
  } catch (error) {}
};

export const unlockCard = (mdata, url, token) => async (dispatch) => {
  try {
    const { response_message, data } = await changeCardStatus(
      mdata,
      url,
      token
    );
    dispatch({ type: UNLOCK_CARD, payload: { response_message, data } });
  } catch (error) {}
};

export const getRateDetail = (id, token) => async (dispatch) => {
  try {
    const { data } = await _fetchRate(id, token);
    dispatch({ type: GET_A_RATE, payload: { data } });
  } catch (error) {}
};

export const filterDocument = (status, token) => async (dispatch) => {
  dispatch({ type: "LOADING" });
  try {
    const { data } = await filterDocumentByStatus(status, token);
    dispatch({ type: FILTER_DOCUMENT, payload: { data } });
  } catch (error) {}
};
export const getDeveloperAccounts = (token) => async (dispatch) => {
  dispatch({ type: "SENDING" });
  try {
    const { data, success } = await fetchDeveloperAccounts(token);
    console.log("developer accounts inside action creators", data);
    dispatch({ type: DEVELOPER_ACCOUNTS_SUCCESS, payload: { success, data } });
  } catch (error) {
    dispatch({ type: DEVELOPER_ACCOUNTS_FAILED, payload: error });
  }
};

export const getDeveloperApiCalls = (token) => async (dispatch) => {
  dispatch({ type: "SENDING" });
  try {
    const { data, success } = await fetchDeveloperApiCalls(token);
    console.log("developer calls inside action creators", data);
    dispatch({ type: DEVELOPER_CALLS_SUCCESS, payload: { success, data } });
  } catch (error) {
    dispatch({ type: DEVELOPER_CALLS_FAILED, payload: error });
  }
};

export const getDeveloperCallStats = (token) => async (dispatch) => {
  dispatch({ type: "SENDING" });
  try {
    const { data, success } = await fetchDeveloperCallStats(token);
    console.log("developer call stats inside action creators", data);
    dispatch({
      type: DEVELOPER_CALL_STATS_SUCCESS,
      payload: { success, data },
    });
  } catch (error) {
    dispatch({ type: DEVELOPER_CALL_STATS_FAILED, payload: error });
  }
};

export const agentAddUsers = (url, mData, token) => async (dispatch) => {
  try {
    const { response_message } = await agentQuery(url, mData, token);
    dispatch({ type: ADD_USER_BY_AGENT, payload: { response_message } });
  } catch (error) {
    dispatch({ type: ADD_USER_BY_AGENT_FAIL, payload: { error } });
  }
};

export const agentUploadUserDoc = (url, formData, token) => async (
  dispatch
) => {
  try {
    const { data } = await _agentUploadUserDoc(url, formData, token);
    dispatch({ type: UPLOAD_USER_DOCS, payload: { data } });
  } catch (error) {}
};

export const addAgentClaim = (url, mData, token) => async (dispatch) => {
  try {
    const { response_message } = await agentQuery(url, mData, token);
    dispatch({ type: ADD_AGENT_CLAIM, payload: { response_message } });
  } catch (error) {}
};
