import React from "react";
import { Modal } from "../../components/Accessories";
import { connect } from "react-redux";
import { getAdminRole } from "../../reduxStore/action/action.creator";

class AddUserModal extends React.Component {
  state = {
    username: "",
    password: "",
    email: "",
    first_name: "",
    last_name: "",
    phone_number: "",
    c_password: "",
    sales_agent_id: this.props.agent_id,
    address: "",
  };

  componentDidMount() {
    // this.props.getAdminRole();
  }

  componentDidUpdate(prevProps, prevState) {}

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  render() {
    return (
      <Modal
        display={this.props.display ? "block" : "none"}
        closeModal={this.props.closeModal}
        modalTitle="Add New User">
        <div className="body-grid">
          <div className="box box9">
            <label>Email</label>

            <div className="input-div">
              <input
                type="email"
                name="email"
                value={this.state.email}
                onChange={this.handleChange}
              />
            </div>
          </div>
          <div className="box box1">
            <label>Firstname</label>

            <div className="input-div">
              <input
                type="text"
                name="first_name"
                value={this.state.first_name}
                id=""
                onChange={this.handleChange}
              />
            </div>
          </div>

          <div className="box box2">
            <label>Lastname</label>

            <div className="input-div">
              <input
                type="text"
                name="last_name"
                value={this.state.last_name}
                id=""
                onChange={this.handleChange}
              />
            </div>
          </div>

          <div className="box box1">
            <label>Phone Number</label>

            <div className="input-div">
              <input
                name="phone_number"
                value={this.state.phone_number}
                onChange={this.handleChange}
              />
            </div>
          </div>

          <div className="box box2">
            <label>Username</label>

            <div className="input-div">
              <input
                type="text"
                name="username"
                value={this.state.username}
                id=""
                onChange={this.handleChange}
              />
            </div>
          </div>

          <div className="box box1">
            <label>Password</label>

            <div className="input-div">
              <input
                type="password"
                name="password"
                value={this.state.password}
                onChange={this.handleChange}
              />
            </div>
          </div>

          <div className="box box2">
            <label>Confirm Password</label>

            <div className="input-div">
              <input
                type="password"
                name="c_password"
                value={this.state.c_password}
                onChange={this.handleChange}
              />
            </div>
          </div>

          <div className="box box9">
            <label>Address</label>

            <div className="input-div">
              <input
                type="text"
                name="address"
                value={this.state.address}
                onChange={this.handleChange}
              />
            </div>
          </div>

          <div className="box box9">
            <div className="text-center">
              <button
                type="button"
                class="btn btn-primary"
                onClick={() => this.props.handleSubmit(this.state)}>
                Add User
              </button>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  data: state.roles.getrole.data,
  agent_id: state.login.data?.sales_agent_id,
});

export default connect(mapStateToProps, {})(AddUserModal);
