import React from "react";
import "../../../morestyles.css";
import {
  InstitutionRow,
  InstitutionTableHead,
} from "../../../components/Rows/ReportTable";
import {
  instituteReport,
  filterInstitutionReport,
  getTransactServices,
} from "../../../reduxStore/action/action.creator";
import { connect } from "react-redux";
import { Loader, DateRange } from "../../../components/Accessories";
import { CSVLink } from "react-csv";
import ReportBar from "./TransactionReport";

class TransactionReport extends React.Component {
  state = {
    year: "",
    startDate: "",
    endDate: "",
    csvReport: {
      data: [],
      filename: "some.csv",
    },
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.startDate != this.state.startDate &&
      prevState.endDate != this.state.endDate
    ) {
      this.props.filterInstitutionReport(
        `get_institution_status_sum?startDate=${this.state.startDate}&endDate=${this.state.endDate}`,
        this.props.token
      );

      let start = JSON.stringify(this.state.startDate),
        end = JSON.stringify(this.state.endDate);
      this.setState({
        year: `${start.substring(1, start.indexOf("-"))} - ${end.substring(
          1,
          end.indexOf("-")
        )}`,
      });
    }
  }

  componentDidMount() {
    this.props.getTransactServices(this.props.token);
    this.props.instituteReport("get_institution_status_sum", this.props.token);
  }

  handleRange = (event, picker) => {
    let start = new Date(picker.startDate).toISOString();
    let end = new Date(picker.endDate).toISOString();

    start = start.substring(0, start.indexOf("T"));
    end = end.substring(0, end.indexOf("T"));
    this.setState({
      startDate: start,
      endDate: end,
    });
  };

  exportCSV = (event, done) => {
    let filename;

    const data = this.reportByInstitute().map((content) => {
      const instituteObject = {};

      instituteObject.ServiceName = content.title;
      instituteObject.Completed = `${content.data[0].count} / ${content.data[0].total}`;
      instituteObject.Paid = `${content.data[3].count} / ${content.data[3].total}`;
      instituteObject.Reversed = `${content.data[5].count} / ${content.data[5].total}`;
      instituteObject.Pending = `${content.data[4].count} / ${content.data[4].total}`;
      instituteObject.InProgress = `${content.data[2].count} / ${content.data[2].total}`;
      instituteObject.Failed = `${content.data[1].count} / ${content.data[1].total}`;
      return instituteObject;
    });
    filename = `institution Report.csv`;

    const report = {
      filename: filename,
      data: data,
      // headers: [],
    };
    this.setState({ csvReport: report }, () => {
      done();
    });
  };

  reportByInstitute = () => {
    const reducerArray = this.props.institutereport.reduce((obj, item) => {
      const institutions = item.institution;

      obj[institutions] = [...(obj[institutions] || []), item];

      return obj;
    }, {});

    const instituteName = Object.keys(reducerArray).map((key) => ({
      title: key,
      data: reducerArray[key].sort((a, b) => {
        let status1 = a.status.toLowerCase(),
          status2 = b.status.toLowerCase();

        if (status1 < status2) {
          return -1;
        }
        if (status1 > status2) {
          return 1;
        }

        return 0;
      }),
    }));

    return instituteName;
  };

  handleRefresh = () => {
    this.props.instituteReport("get_institution_status_sum", this.props.token);
  };

  render() {
    let iteminTable;

    if (this.props.loading) {
      iteminTable = <Loader loading={this.props.loading} />;
    } else {
      iteminTable = (
        <>
          <InstitutionTableHead title={this.state.key} />
          <tbody>
            {/* {this.props.institutereport && (
              <InstitutionRow report={this.props.institutereport} />
            )} */}
            {this.props.institutereport &&
              this.props.institutereport.map((content) => (
                <InstitutionRow {...content} service={this.props.service} />
              ))}
          </tbody>
        </>
      );
    }
    return (
      <>
        <ReportBar active="instituteStatus" handleRefresh={this.handleRefresh}>
          <div class="card-header">
            <div className="row">
              <div className="col-md-8">
                <h3 class="mb-0">
                  {this.state.year != "" &&
                    `Institution Report for ${this.state.year}`}
                </h3>
              </div>
              <div className="col-md-4">
                <div className="row">
                  <div className="col-md-8">
                    <DateRange handleRange={this.handleRange} />
                  </div>
                  <div className="col-md-4">
                    <CSVLink
                      {...this.state.csvReport}
                      asyncOnClick={true}
                      className="btn btn-sm btn-neutral"
                      onClick={this.exportCSV}>
                      <i className="fa fa-export"></i> Export CSV
                    </CSVLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="table-responsive py-4">
            <table class="table table-flush" id="datatable">
              {iteminTable}
            </table>
          </div>
        </ReportBar>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  institutereport: state.report.transaction_report.institutereport,
  loading: state.report.transaction_report.loading,
  service: state.alltransactions.transcategory.service,
  token: state.login.token,
});

export default connect(mapStateToProps, {
  instituteReport,
  filterInstitutionReport,
  getTransactServices,
})(TransactionReport);
