import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
// import "./morestyles.css";
import AuthenticatedRoute from "./Routing/AuthenticatedRoute";

function App() {
  return (
    <Router>
      <AuthenticatedRoute />
    </Router>
  );
}

export default App;
