import React from "react";
import { Modal } from "../../components/Accessories";
import { connect } from "react-redux";
import { getAdminRole } from "../../reduxStore/action/action.creator";

class AddUserModal extends React.Component {
  state = {
    user_id: "",
    kyc_level_name: "",
    kyc_level_id: "",
    name: "",
    identification_number: "",
    images: null,
  };

  componentDidMount() {
    // this.props.getAdminRole();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.user_id != prevProps.user_id) {
      this.setState({
        user_id: this.props.user_id.id,
      });
    }
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleFile = (e) => {
    this.setState({
      images: e.target.files[0],
      file: URL.createObjectURL(e.target.files[0]),
    });
  };

  handleSubmit = () => {
    const formData = new FormData();
    formData.append("user_id", this.state.user_id);
    formData.append("kyc_level_name", this.state.kyc_level_name);
    formData.append("kyc_level_id", this.state.kyc_level_id);
    formData.append("name", this.state.name);
    formData.append("identification_number", this.state.identification_number);
    formData.append("images", this.state.images);

    this.props.uploadUserDoc(formData);

    // callBackMethod(
    //   this.props.uploadProductImage(
    //     "savinvest/investment_product_image",
    //     this.state.product,
    //     formData,
    //     this.props.token
    //   )
    // )
    //   .then(() => this.setState({ ...this.state, upload: false }))
    //   .then(() =>
    //     alert(JSON.stringify(this.props.upload_resp.response_message))
    //   );
  };

  render() {
    return (
      <Modal
        display={this.props.display ? "block" : "none"}
        closeModal={this.props.closeModal}
        modalTitle="Upload Doc for User">
        <div className="body-grid">
          <div className="box box1">
            <label>Kyc Level Name</label>

            <div className="input-div">
              <select
                type="text"
                name="kyc_level_name"
                value={this.state.kyc_level_name}
                onChange={this.handleChange}>
                <option></option>
                {this.props.levels &&
                  this.props.levels.map((content) => (
                    <option value={content.level_name}>
                      {content.level_name}
                    </option>
                  ))}
              </select>
            </div>
          </div>
          <div className="box box2">
            <label>Kyc Level Id</label>

            <div className="input-div">
              <select
                type="text"
                name="kyc_level_id"
                value={this.state.kyc_level_id}
                onChange={this.handleChange}>
                <option></option>
                {this.props.levels &&
                  this.props.levels.map((content) => (
                    <option value={content.id}>{content.id}</option>
                  ))}
              </select>
            </div>
          </div>

          <div className="box box1">
            <label>Name</label>

            <div className="input-div">
              <input
                type="text"
                name="name"
                value={this.state.name}
                id=""
                onChange={this.handleChange}
              />
            </div>
          </div>

          <div className="box box2">
            <label>Identification Number</label>

            <div className="input-div">
              <input
                type="text"
                name="identification_number"
                value={this.state.identification_number}
                id=""
                onChange={this.handleChange}
              />
            </div>
          </div>

          <div className="box box9">
            <label>Upload Image</label>
            <input
              className="form-control"
              type="file"
              name="images"
              accept="image/png, image/jpeg"
              onChange={this.handleFile}
            />
          </div>

          <div className="box box9">
            <div className="text-center">
              <button
                type="button"
                class="btn btn-primary"
                onClick={this.handleSubmit}>
                Add User
              </button>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  data: state.roles.getrole.data,
  agent_id: state.login.data?.sales_agent_id,
  levels: state.kyc.get_kyc_level.levels,
});

export default connect(mapStateToProps, {})(AddUserModal);
