import React from "react";
import { connect } from "react-redux";
import SideBar from "../../../components/SideBar";
import DashboardNavBar from "../../../components/DashboardNavbar";
import {
  getAllPromotionSub,
  getUsers,
  getServiceCode,
} from "../../../reduxStore/action/action.creator";
import {
  SubscriberHead,
  SubscriberBody,
} from "../../../components/Rows/PromotionTable";
import { callBackMethod, Loader } from "../../../components/Accessories";

class SubscriptionReport extends React.Component {
  state = {};

  componentDidMount() {
    callBackMethod(this.props.getAllPromotionSub(this.props.token)).then(
      () =>
        this.props.subscribers.map((content) => {
          // alert(JSON.stringify(content.categoryId));
          this.props.getServiceCode(content.categoryId, this.props.token);
        })
      //
    );

    // this.props.getUsers(this.props.token, `user/users-all`);
  }

  handleRefresh = () => {
    this.props.getAllPromotionSub(this.props.token);
  };

  render() {
    let itemInTable;
    if (this.props.loading) {
      itemInTable = <Loader loading={this.props.loading} />;
    } else {
      itemInTable = (
        <>
          <SubscriberHead />
          <tbody>
            {this.props.subscribers &&
              this.props.subscribers.map((content, index) => (
                <SubscriberBody
                  {...content}
                  index={index + 1}
                  service={this.props.servicecode[index]}
                />
              ))}
          </tbody>
        </>
      );
    }
    return (
      <>
        <div>
          <SideBar />
          <div class="main-content" id="panel">
            <DashboardNavBar
              submenu="Report Summary"
              report={false}
              getInfo={this.handleRefresh}
              handleReport={this.getTotalTransaction}
            />
            <div class="container-fluid mt--6">
              <div class="row">
                <div class="col">
                  <div class="card">
                    <div class="card-header">
                      <div className="row">
                        <div className="col-md-8">
                          <h3 class="mb-0">Subscription Report</h3>
                        </div>
                      </div>
                    </div>
                    <div className="table-responsive py-4">
                      <table className="table table-flush" id="datatable-basic">
                        {itemInTable}
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  loading: state.promotions.getSubscribers.loading,
  subscribers: state.promotions.getSubscribers.subcribers,
  users: state.users.allusers.usersData,
  token: state.login.token,
  servicecode: state.alltransactions.transcategory.serviceCode,
});
export default connect(mapStateToProps, {
  getAllPromotionSub,
  getUsers,
  getServiceCode,
})(SubscriptionReport);
