import { combineReducers } from "redux";
import {
  GET_BILL_LIST,
  GET_BILL_TRANS,
  SWITCH_BILL,
} from "../action/action.types";

const initialState = {
  total: 0,
};

const billerReducer = (state = initialState, action) => {
  switch (action.type) {
    case "LOADING":
      return {
        ...state,
        loading: true,
      };
    case GET_BILL_LIST:
      return {
        ...state,
        loading: false,
        billerlist: action.payload.result,
      };
    case GET_BILL_TRANS:
      return {
        ...state,
        loading: false,
        billertrans: action.payload.transactions,
        total: action.payload.allTransactions,
      };
    default:
      return state;
  }
};

const switchBillReducer = (state = {}, action) => {
  switch (action.type) {
    case SWITCH_BILL:
      return {
        ...state,
        switch_message: action.payload.message,
      };
    default:
      return state;
  }
};

export const initialBillReducer = combineReducers({
  allbilllist: billerReducer,
  switchbill: switchBillReducer,
});
