import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import AllUsers from "./AllUsers";
import AllUserDetails from "./AllUserDetails";

const AllUsersRoute = ({ match }) => (
  <>
    <Switch>
      <Route exact path={`${match.path}/`} component={AllUsers} />
      <Route
        exact
        path={`${match.path}/:name/details`}
        component={AllUserDetails}
      />
    </Switch>
  </>
);
export default AllUsersRoute;
