import React from "react";
import { connect } from "react-redux";
import { DateRange, Modal } from "../../components/Accessories";

class UserFilterModal extends React.Component {
  state = {
    date: "",
    email: "",
    name: "",
    phone: "",
    username: "",
    startDate: null,
    endDate: null,
  };

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  handleRange = (event, picker) => {
    let start = new Date(picker.startDate).toISOString();
    let end = new Date(picker.endDate).toISOString();

    start = start.substring(0, start.indexOf("T"));
    end = end.substring(0, end.indexOf("T"));
    this.setState({
      startDate: start,
      endDate: end,
    });
  };

  handleSubmit = () => {
    this.props.submit(this.state);
  };

  render() {
    return (
      <Modal
        display={this.props.display ? "block" : "none"}
        closeModal={this.props.closeModal}>
        <div className="body-grid">
          <div className="box box9">
            <label>Select Date Range</label>
            <DateRange handleRange={this.handleRange} />
          </div>
          <div className="box box1">
            <label>Enter Email</label>
            <input
              name="email"
              value={this.state.email}
              className="form-control"
              onChange={this.handleChange}
            />
          </div>
          <div className="box box2">
            <label>Enter Name</label>
            <input
              type="text"
              name="name"
              value={this.state.name}
              onChange={this.handleChange}
              className="form-control"
            />
          </div>
          <div className="box box1">
            <label>Enter Phone Number</label>
            <input
              type="text"
              name="phone"
              value={this.state.phone}
              onChange={this.handleChange}
              className="form-control"
            />
          </div>
          <div className="box box2">
            <label>Enter Username</label>
            <input
              type="text"
              name="username"
              value={this.state.username}
              onChange={this.handleChange}
              className="form-control"
            />
          </div>
          <div className="box box9">
            <div className="text-center">
              <button
                type="button"
                class="btn btn-primary"
                onClick={this.handleSubmit}>
                Filter Users
              </button>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

export default connect(null, {})(UserFilterModal);
