import React from "react";
import { connect } from "react-redux";
import SideBar from "../../components/SideBar";
import DashboardNavBar from "../../components/DashboardNavbar";
import {
  List,
  UserTransactionList,
  UserTransactionServiceList,
  UserStatement,
} from "../../components/Rows";
import { callBackMethod, Modal, Loader } from "../../components/Accessories";
import {
  detailsInfo,
  tabDetail,
  newTabDetail,
  accountBalance,
  accountStatement,
  loadHistory,
  getKycDocuments,
} from "../../reduxStore/action/action.creator";
import {
  LoginHistoryHead,
  LoginHistoryBody,
  UserDocumentHead,
  UserDocumentBody,
} from "../../components/Rows/UserTable";

class AllUsersDetails extends React.Component {
  state = {
    key: "kyc",
    active: "",
    display: false,
    currentPage: 1,
    total: 0,
    filterArray: [],
    begin: "2020-01-01",
    stop: "2020-11-05",
  };

  componentDidMount() {
    let index = this.props.history.location.pathname;
    index = index.substring(index.indexOf("s/") + 2, index.indexOf("/d"));

    // this.props.detailsInfo(`user/users-profile/${index}`, this.props.token);

    callBackMethod(
      this.props.detailsInfo(`user/users-profile/${index}`, this.props.token)
    ).then(() => {
      if (
        this.props.details.data.bank_account.length > 0 &&
        this.props.details.data.bank_account[0].account_number
      ) {
        this.props.accountBalance(
          this.props.details.data.bank_account[0].account_number,
          this.props.token
        );
      }
    });

    // this.props.detailsInfo();
    this.props.tabDetail(
      `transaction/get_history/${this.props.history.location.state}`,
      this.props.token
    );

    this.props.getKycDocuments(this.props.token);
  }

  loadBalance = () => {
    if (
      this.props.details.data.bank_account.length > 0 &&
      this.props.details.data.bank_account[0].account_number
    ) {
      this.props.accountBalance(
        this.props.details.data.bank_account[0].account_number,
        this.props.token
      );
    }
  };

  loadStatement = () => {
    if (this.props.details.data.bank_account.length > 0) {
      let acc = this.props.details.data.bank_account[0].account_number;

      this.props.accountStatement(
        this.state.begin,
        this.state.stop,
        acc,
        this.props.token
      );
    }
  };

  // pagination controls ends...

  getActiveState = (k) => {
    this.setState({ key: k });
  };

  getModal = () => {
    this.setState({
      display: true,
    });
  };

  goBack = () => {
    this.props.history.goBack();
  };

  getDetails = (url) => () => {
    this.props.tabDetail(url, this.props.token);
  };

  handleRange = (event, picker) => {
    let start = new Date(picker.startDate).toISOString();
    let end = new Date(picker.endDate).toISOString();

    start = start.substring(0, start.indexOf("T"));
    end = end.substring(0, end.indexOf("T"));

    this.props.newTabDetail(
      `transaction/get_history/${this.props.history.location.state}?startDate=${start}&endDate=${end}`,
      this.props.token
    );
  };

  handleRangeStatement = (event, picker) => {
    let begin = new Date(picker.startDate).toISOString();
    let stop = new Date(picker.endDate).toISOString();

    begin = begin.substring(0, begin.indexOf("T"));
    stop = stop.substring(0, stop.indexOf("T"));

    let acc = this.props.details.data.bank_account[0].account_number;
    this.props.accountStatement(begin, stop, acc, this.props.token);
  };

  render() {
    let itemToRender;
    let itemInBody;

    let index = this.props.history.location.pathname;
    index = index.substring(index.indexOf("s/") + 2, index.indexOf("/d"));

    if (this.props.doc_load) {
      itemInBody = "loading...";
    } else {
      itemInBody = (
        <>
          {this.props.kyc_docs &&
            this.props.kyc_docs.data.map((content) => {
              if (content.user_id == index) {
                return <UserDocumentBody {...content} />;
              }
            })}
        </>
      );
    }

    if (this.props.loading) {
      itemToRender = <Loader loading={this.props.loading} />;
    } else {
      const pic = this.props.details
        ? this.props.details.data.profile_photo
        : "";
      const completeUrl = `${process.env.REACT_APP_DOCUMENT_BASE_URL}${pic}`;
      itemToRender = (
        <>
          {this.props.details && (
            <>
              <div class="header pb-6" style={{ backgroundColor: "#0038ba" }}>
                <div class="container-fluid">
                  <div class="header-body">
                    <div class="row align-items-center py-4">
                      <div
                        className="col-lg-12 col-7"
                        style={{ paddingBottom: "1em" }}>
                        <span
                          onClick={this.goBack}
                          style={{ cursor: "pointer" }}>
                          <span
                            style={{
                              padding: "0 .5em 0 0",
                              textAlign: "center",
                            }}>
                            <i
                              class="fas fa-chevron-left"
                              style={{
                                color: "#fff",
                                padding: "0.3em .5em",
                                border: "2px solid #fff",
                                borderRadius: "50%",
                                fontSize: "12px",
                              }}></i>
                          </span>
                          <span style={{ color: "#fff" }}>Back</span>
                        </span>
                      </div>
                      <div class="col-lg-6 col-7">
                        <div className="col-lg-12 col-12">
                          <div className="wrap">
                            <div className="row">
                              <div className="col-md-3">
                                {/* <div className="img-div bg-success"></div> */}
                                {this.props.details === null ? (
                                  <img
                                    alt="Image placeholder"
                                    src="../../assets/img/theme/team-4.jpg"
                                    class="avatar rounded-circle"
                                  />
                                ) : (
                                  <img
                                    src={completeUrl}
                                    alt="profile-photo"
                                    width="100"
                                    height="100"
                                    style={{
                                      borderRadius: "100%",
                                    }}
                                  />
                                )}
                              </div>
                              <div className="update-partner-btn col-md-5">
                                <button onClick={this.getModal}>
                                  <i className="fa fa-edit"></i>
                                  Update Customer
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-12 col-12">
                          <div className="wrap" style={{ paddingTop: ".5em" }}>
                            <div className="row">
                              <div className="trans-email col-md-12">
                                <div>{this.props.details.data.email}</div>
                              </div>
                              <div className="trans-name col-md-12">
                                <div>{this.props.details.data.username}</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="container-fluid mt--5">
                <div
                  class="row"
                  style={{ display: "flex", borderRadius: "2em" }}>
                  <div class="col-5 userDetailsCol1">
                    <table class="table table-hover mt-5">
                      <tbody>
                        <List
                          {...this.props.details.data}
                          balance={
                            this.props.bal != undefined
                              ? this.props.bal
                              : "0.00"
                          }
                        />
                      </tbody>
                    </table>
                  </div>
                  <div class="col-7 userDetailsCol2">
                    <div class="tab" role="tabpanel">
                      <div
                        class="card-header"
                        style={{
                          margin: "0px",
                          backgroundColor: "lavenderblush",
                          borderBottom: "0px",
                        }}>
                        <ul class="nav nav-tabs mt--3 ml--4" role="tablist">
                          <li role="presentation">
                            <a
                              href="#Section1"
                              aria-controls="wallets"
                              role="tab"
                              onClick={this.loadBalance}
                              data-toggle="tab">
                              <i class="fa fa-dollar"> &nbsp; </i>Balances
                            </a>
                          </li>
                          <li role="presentation">
                            <a
                              href="#Section2"
                              aria-controls="transactions"
                              role="tab"
                              data-toggle="tab">
                              <i class="fa fa-credit-card"> &nbsp; </i>
                              Transactions
                            </a>
                          </li>
                          <li role="presentation">
                            <a
                              href="#Section3"
                              aria-controls="statement"
                              role="tab"
                              onClick={this.loadStatement}
                              data-toggle="tab">
                              <i class="fa fa-users"> &nbsp; </i>Account
                              Statement
                            </a>
                          </li>
                          <li role="presentation">
                            <a
                              href="#Section4"
                              aria-controls="services"
                              role="tab"
                              data-toggle="tab">
                              <i class="fa fa-briefcase"> &nbsp; </i>Services
                              Used
                            </a>
                          </li>
                          <li role="presentation" class="active">
                            <a
                              href="#Section5"
                              aria-controls="kyc"
                              role="tab"
                              data-toggle="tab">
                              <i class="fa fa-user"> &nbsp; </i>KYC
                            </a>
                          </li>
                          <li role="presentation">
                            <a
                              href="#Section6"
                              aria-controls="referrals"
                              role="tab"
                              data-toggle="tab">
                              <i class="fa fa-users"> &nbsp; </i>Referrals
                            </a>
                          </li>
                          <li role="presentation">
                            <a
                              href="#Section7"
                              aria-controls="history"
                              role="tab"
                              onClick={() =>
                                this.props.loadHistory(
                                  this.props.details.data.id,
                                  this.props.token
                                )
                              }
                              data-toggle="tab">
                              <i className="">&nbsp; </i>Login History
                            </a>
                          </li>
                          <li role="presentation">
                            <a
                              href="#Section8"
                              aria-controls="document"
                              role="tab"
                              data-toggle="tab">
                              <i className="">&nbsp; </i>User Document
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div class="tab-content tabs">
                        <div
                          role="tabpanel"
                          class="tab-pane fade"
                          id="Section1">
                          <h3>Balances</h3>
                          <table class="table table-bordered table-hover tableClass">
                            <thead>
                              <tr class="table-primary">
                                <th scope="col" style={{ fontWeight: "bold" }}>
                                  S/N
                                </th>
                                <th scope="col" style={{ fontWeight: "bold" }}>
                                  Account Number
                                </th>
                                <th scope="col" style={{ fontWeight: "bold" }}>
                                  Availbale Balance
                                </th>
                                <th scope="col" style={{ fontWeight: "bold" }}>
                                  Ledger Balance
                                </th>
                              </tr>
                            </thead>
                            {this.props.bal && (
                              <tbody>
                                <tr>
                                  <td></td>
                                  <td>{this.props.bal.data.account_number}</td>
                                  <td>
                                    {this.props.bal.data.available_balance}
                                  </td>
                                  <td>{this.props.bal.data.book_balance}</td>
                                </tr>
                              </tbody>
                            )}
                          </table>
                        </div>
                        <div
                          role="tabpanel"
                          class="tab-pane fade"
                          id="Section2">
                          <div
                            className="table-responsive"
                            style={{ maxHeight: "34em" }}>
                            {this.props.tabdetail && !this.props.loading && (
                              <UserTransactionList
                                {...this.props.tabdetail}
                                range={this.handleRange}
                              />
                            )}
                          </div>
                        </div>
                        <div
                          role="tabpanel"
                          class="tab-pane fade"
                          id="Section3">
                          <div
                            className="table-responsive"
                            style={{ maxHeight: "34em" }}>
                            {this.props.statementDetail &&
                              !this.props.loading && (
                                <UserStatement
                                  {...this.props.statementDetail}
                                  range={this.handleRangeStatement}
                                />
                              )}
                          </div>
                        </div>
                        <div
                          role="tabpanel"
                          class="tab-pane fade"
                          id="Section4">
                          <div
                            className="table-responsive"
                            style={{ maxHeight: "34em" }}>
                            {this.props.tabdetail && !this.props.loading && (
                              <UserTransactionServiceList
                                {...this.props.tabdetail}
                              />
                            )}
                          </div>
                        </div>
                        <div
                          role="tabpanel"
                          class="tab-pane fade active"
                          id="Section5">
                          <div class="card">
                            <ul class="list-group list-group-flush">
                              <li class="list-group-item">
                                <span
                                  style={{
                                    fontWeight: "bold",
                                    fontSize: "13px",
                                  }}>
                                  KYC Fields{" "}
                                </span>
                                <br />
                                <span
                                  style={{
                                    fontWeight: "bold",
                                    fontSize: "12px",
                                  }}>
                                  BVN :
                                </span>
                                <span style={{ fontSize: "12px" }}>
                                  {this.props.details.data.bvn
                                    ? this.props.details.data.bvn
                                    : "User has no bvn"}
                                </span>
                              </li>
                              <li class="list-group-item">
                                <span
                                  style={{
                                    fontWeight: "bold",
                                    fontSize: "13px",
                                  }}>
                                  KYC Level{" "}
                                </span>
                                <br />
                                <span style={{ fontSize: "12px" }}>
                                  {this.props.details.data.kyc_level
                                    ? this.props.details.data.kyc_level
                                    : 0}
                                </span>
                              </li>
                              <li class="list-group-item">
                                <span
                                  style={{
                                    fontWeight: "bold",
                                    fontSize: "13px",
                                  }}>
                                  KYC Document{" "}
                                </span>
                                <br />
                                <span style={{ fontSize: "11px" }}>N/A</span>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div
                          role="tabpanel"
                          class="tab-pane fade"
                          id="Section6">
                          <h3>Referrals</h3>
                        </div>
                        <div
                          role="tabpanel"
                          className="tab-pane fade"
                          id="Section7">
                          <table className="table table-bordered table-hover tableClass table-responsive">
                            <LoginHistoryHead />
                            <tbody>
                              {this.props.login_history &&
                                this.props.login_history.map((content) => (
                                  <LoginHistoryBody {...content} />
                                ))}
                            </tbody>
                          </table>
                        </div>
                        <div
                          role="tabpanel"
                          className="tab-pane fade"
                          id="Section8">
                          <table className="table table-bordered table-hover tableClass table-responsive">
                            <UserDocumentHead />
                            <tbody>{itemInBody}</tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      );
    }
    return (
      <div>
        <div>
          <SideBar />
          <div className="main-content" id="panel">
            <DashboardNavBar settings="false" />
            {itemToRender}
            {/* {this.props.details && JSON.stringify(this.props.details.data)} */}
          </div>
        </div>
        <Modal
          closeModal={this.closeModal}
          modalTitle="something"
          display={this.state.display ? "block" : "none"}></Modal>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  details: state.details.user_detailinfo,
  loading: state.details.loading,
  doc_load: state.kyc.kyc_doc.loading,
  tabdetail: state.details.tabdetail,
  bal: state.details.bal,
  statementDetail: state.details.statementDetail,
  login_history: state.details.login_history,
  failed: state.details.failed,
  token: state.login.token,
  kyc_docs: state.kyc.kyc_doc.documents,
});
export default connect(mapStateToProps, {
  detailsInfo,
  tabDetail,
  accountBalance,
  accountStatement,
  newTabDetail,
  loadHistory,
  getKycDocuments,
})(AllUsersDetails);
