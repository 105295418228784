import {
  FILTER_TRANS_REPORT_INSTITUTE,
  FILTER_TRANS_REPORT_YEAR,
  FILTER_USER_REPORT,
  GET_INSTITUTE_STATUS,
  REPORT_BY_INSTITUTE,
  TRANSACTION_REPORT,
  USER_REPORT_INFO,
  FILTER_INSTITUTE_REPORT,
  GET_SERVICE_REPORT,
} from "../action/action.types";
import { combineReducers } from "redux";

const getUserReportReducer = (state = {}, action) => {
  switch (action.type) {
    case "LOADING":
      return {
        ...state,
        loading: true,
      };
    case USER_REPORT_INFO:
      return {
        ...state,
        loading: false,
        usersreport: action.payload.data,
      };
    case FILTER_USER_REPORT:
      return {
        ...state,
        loading: false,
        usersreport: action.payload.data,
      };
    default:
      return state;
  }
};

const getTransactionReportReducer = (state = {}, action) => {
  switch (action.type) {
    case "LOADING":
      return {
        ...state,
        loading: true,
      };
    case TRANSACTION_REPORT:
      return {
        ...state,
        loading: false,
        transactionreport: action.payload.transactionsArr,
      };
    case REPORT_BY_INSTITUTE:
      return {
        ...state,
        loading: false,
        transactionreport: action.payload.transactionsArr,
      };
    case FILTER_TRANS_REPORT_YEAR:
      return {
        ...state,
        loading: false,
        transactionreport: action.payload.transactionsArr,
      };
    case FILTER_TRANS_REPORT_INSTITUTE:
      return {
        ...state,
        loading: false,
        transactionreport: action.payload.transactionsArr,
      };
    case GET_INSTITUTE_STATUS:
      return {
        ...state,
        loading: false,
        institutereport: action.payload.transactionsArr,
      };
    case GET_SERVICE_REPORT:
      return {
        ...state,
        loading: false,
        institutereport: action.payload.transactionsArr,
      };
    case FILTER_INSTITUTE_REPORT:
      return {
        ...state,
        loading: false,
        institutereport: action.payload.transactionsArr,
      };
    default:
      return state;
  }
};

export const reportReducer = combineReducers({
  user_report: getUserReportReducer,
  transaction_report: getTransactionReportReducer,
});
