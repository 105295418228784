import React from "react";
import { dateFormatter } from "../Row";

export const PromotionHead = (props) => (
  <thead className="thead-light">
    <tr>
      <th>S/N</th>
      <th>Name</th>
      <th>Description</th>
      <th>Category Name</th>
      <th>Service Name</th>
      {/* <th>Transaction Category Applicable</th> */}
      <th>Service Applicable</th>
      <th>Customer Max Transaction Count</th>
      <th>Subscription Count</th>
      <th>Max Subscription Permit</th>
      <th>Start Date</th>
      <th>End Date</th>
      <th style={{ textAlign: "center" }}>Actions</th>
      <th></th>
    </tr>
  </thead>
);

export const PromotionRow = (props) => (
  <>
    <tr>
      <td>{props.index}</td>
      <td>{props.name}</td>
      <td>{props.description}</td>
      <td>{props.categoryId.name}</td>
      <td>{props.serviceId.serviceName}</td>
      {/* <td>{props.categoryId.name}</td> */}
      <td>{props.serviceApplicable}</td>
      <td>{props.customerMaxTxnCount}</td>
      <td>{props.subscriptionCount}</td>
      <td>{props.maxSubscriptionPermit}</td>
      <td>{dateFormatter(props.startDate)}</td>
      <td>{dateFormatter(props.endDate)}</td>
      <td
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}>
        <i
          className="fa fa-trash-o"
          style={{ padding: "0 .25em" }}
          onClick={() => props.deletePromo(props._id)}></i>
        <i
          className="fa fa-pencil"
          style={{ padding: "0 .25em" }}
          onClick={() => props.editPromotion(props)}></i>

        <label style={{ display: "inline-block" }} class="custom-toggle">
          <input
            type="checkbox"
            onChange={() => props.togglePromotion(props._id, props.enabled)}
            checked={props.enabled}
            // data-toggle="modal"
            // data-target="#exampleModal"
          />
          <span class="custom-toggle-slider rounded-circle"></span>
        </label>
        <i
          class="fas fa-eye"
          style={{ paddingLeft: ".5em" }}
          onClick={() => props.goToDetails(props._id)}></i>
      </td>
      <td>
        <button
          className="btn btn-primary"
          onClick={() => props.viewSubscribers(props.serviceApplicable)}>
          <span>view subscribers</span>
        </button>
      </td>
    </tr>
  </>
);

export const SubscriberHead = () => (
  <thead className="thead-light">
    <tr>
      <th>S/N</th>
      <th>User Id</th>
      <th>Service Name</th>
      <th>Transaction Service</th>
      <th>Waved Fee</th>
      <th>Created At</th>
      <th>Action</th>
    </tr>
  </thead>
);

export const SubscriberBody = (props) => (
  <tr>
    {/* {alert(JSON.stringify(props.service))} */}
    <td>{props.index}</td>
    <td>{props.userId}</td>
    <td>{}</td>
    <td>{props.transactionRef ? props.transactionRef : "not given"}</td>
    <td>{props.wavedFee}</td>
    <td>{dateFormatter(props.createdAt)}</td>
    <td></td>
  </tr>
);

export const PromotionSubscriberHead = () => (
  <thead className="thead-light">
    <tr>
      <th>S/N</th>
      <th>User Id</th>
      <th>Service Code</th>
      <th>Category Id</th>
      <th>Waved Fee</th>
      <th>Created At</th>
      <th>Action</th>
    </tr>
  </thead>
);
export const PromotionSubscriberBody = (props) => (
  <tr>
    <td>{props.index}</td>
    <td>{props.userId}</td>
    <td>{props.serviceCode}</td>
    <td>{props.categoryId}</td>
    <td>{props.wavedFee}</td>
    <td>{dateFormatter(props.createdAt)}</td>
    <td></td>
  </tr>
);
