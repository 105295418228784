import React from "react";
import { Modal } from "../../components/Accessories";
import { connect } from "react-redux";
import { getAdminRole } from "../../reduxStore/action/action.creator";

class AddUserModal extends React.Component {
  state = {
    user_email_or_phone_no: "",
    sales_agent_id: this.props.agent_id,
  };

  componentDidMount() {
    // this.props.getAdminRole();
  }

  componentDidUpdate(prevProps, prevState) {}

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleSubmit = () => {
    this.props.addClaim(this.state);
  };

  render() {
    return (
      <Modal
        display={this.props.display ? "block" : "none"}
        closeModal={this.props.closeModal}
        modalTitle="Claim Users">
        <div className="body-grid">
          <div className="box box9">
            <label>Email or Phone Number</label>

            <div className="input-div">
              <input
                type="text"
                name="user_email_or_phone_no"
                value={this.state.user_email_or_phone_no}
                id=""
                onChange={this.handleChange}
              />
            </div>
          </div>

          <div className="box box9">
            <div className="text-center">
              <button
                type="button"
                class="btn btn-primary"
                onClick={this.handleSubmit}>
                Add Claim
              </button>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  data: state.roles.getrole.data,
  agent_id: state.login.data?.sales_agent_id,
  levels: state.kyc.get_kyc_level.levels,
});

export default connect(mapStateToProps, {})(AddUserModal);
