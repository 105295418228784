import { DEVELOPER_ACCOUNTS_SUCCESS, DEVELOPER_ACCOUNTS_FAILED,  DEVELOPER_CALLS_SUCCESS, DEVELOPER_CALLS_FAILED, DEVELOPER_CALL_STATS_SUCCESS, DEVELOPER_CALL_STATS_FAILED } from '../action/action.types';

import { combineReducers } from "redux"

const initialState = {
  developerAccountsData: [],
  developerApiCallsData: [],
  developerCallStatsData: [],
  response_msg: ""
}

const developerAccountsReducer = (state = {}, action) => {
  switch (action.type) {
    case "SENDING":
      return {
        ...state,
        loading: true,
        update_message: "",
      };
    case DEVELOPER_ACCOUNTS_SUCCESS:
      return {
        ...state,
        developerAccountsData: action.payload.data,
        loading: false,
      };
    case DEVELOPER_ACCOUNTS_FAILED:
      return {
        ...state,
        failed: action.payload,
      };
    default:
      return state;
  }
}

const developerApiCallsReducer = (state = {}, action) => {
  switch (action.type) {
    case "SENDING":
      return {
        ...state,
        loading: true,
        update_message: "",
      };
    case DEVELOPER_CALLS_SUCCESS:
      return {
        ...state,
        developerApiCallsData: action.payload.data,
        loading: false,
      };
    case DEVELOPER_CALLS_FAILED:
      return {
        ...state,
        failed: action.payload,
      };
    default:
      return state;
  }
}

const developerCallStatsReducer = (state = {}, action) => {
  switch (action.type) {
    case "SENDING":
      return {
        ...state,
        loading: true,
        update_message: "",
      };
    case DEVELOPER_CALL_STATS_SUCCESS:
      return {
        ...state,
        developerCallStatsData: action.payload.data,
        loading: false,
      };
    case DEVELOPER_CALL_STATS_FAILED:
      return {
        ...state,
        failed: action.payload,
      };
    default:
      return state;
  }
}

export const developerAccounts = combineReducers({
  developerAccounts: developerAccountsReducer,
  developerApiCalls: developerApiCallsReducer,
  developerCallStats: developerCallStatsReducer
});