import React from "react";
import { connect } from "react-redux";
import SideBar from "../../components/SideBar";
import DashboardNavBar from "../../components/DashboardNavbar";
import { TableHead, Row } from "../../components/Rows";
import {
  getUsers,
  detailsInfo,
  tabDetail,
  filterUserTable,
  enableLock,
  disableLock,
  filterAllUsers,
  logOut,
  agentAddUsers,
  getKycLevel,
  agentUploadUserDoc,
  addAgentClaim,
} from "../../reduxStore/action/action.creator";
import "../../morestyles.css";
import {
  callBackMethod,
  Loader,
  Modal,
  Pagination,
} from "../../components/Accessories";
import UserFilterModal from "./UserFilterModal";
import AddUserModal from "./AddUserModal";
import UploadDocModal from "./UploadDocModal";
import ClaimModal from "./ClaimModal";

class AllUsers extends React.Component {
  state = {
    currentPage: 1,
    display: false,
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.response_msg && !this.props.loading) {
      // alert(nextProps.response_msg);
      this.props.getUsers(
        this.props.token,
        `user/get_users/sales_agents/${this.props.agent_id}?page=${this.state.currentPage}`
      );
      // alert(JSON.stringify(nextProps.history));
    }
  }

  // componentDidUpdate(prevProps, prevState) {
  //   if (prevProps.response_msg && !prevProps.history) {
  //     this.props.getUsers(
  //       this.props.token,
  //       `user/users-all?page=${this.state.currentPage}`
  //     );
  //   }
  // }

  componentDidMount() {
    this.props.getUsers(
      this.props.token,
      `user/get_users/sales_agents/${this.props.agent_id}`
    );

    this.props.getKycLevel(this.props.token);
  }

  changePage = (id) => () => {
    if (this.props.filtering != true) {
      this.props.getUsers(this.props.token, `user/users-all?page=${id}`);
    } else {
      this.props.filterUserTable(this.state.filterbyItem, id, this.props.token);
    }
    this.setState({
      currentPage: id,
    });
  };
  goPrevious = () => {
    if (this.state.currentPage - 1 != 0) {
      const id = this.state.currentPage - 1;
      if (this.props.filtering != true) {
        this.props.getUsers(this.props.token, `user/users-all?page=${id}`);
      } else {
        this.props.filterUserTable(
          this.state.filterbyItem,
          id,
          this.props.token
        );
      }
      this.setState({
        currentPage: id,
      });
    }
  };
  goNext = () => {
    const total = parseInt(Math.ceil(this.props.total / 15));
    if (this.state.currentPage != total) {
      const id = this.state.currentPage + 1;
      if (this.props.filtering != true) {
        this.props.getUsers(this.props.token, `user/users-all?page=${id}`);
      } else {
        this.props.filterUserTable(
          this.state.filterbyItem,
          id,
          this.props.token
        );
      }
      this.setState({
        currentPage: id,
      });
    }
  };

  handleNextPage = (props) => {
    const { id, index } = props;
    // this.props.detailsInfo(`user/users-profile/${index}`);
    // this.props.tabDetail(`transaction/get_history/${id}`);

    this.props.history.push({
      pathname: `${this.props.match.url}/${id}/details`,
      state: id,
    });
  };

  getIndex = (index) => {
    const newIndex = 15 * (this.state.currentPage - 1) + index;
    return newIndex;
  };

  _searchField = async (item) => {
    // alert(item);
    this.props.filterUserTable(item, 1, this.props.token);
    this.setState({
      ...this.state,
      filterbyItem: item,
    });
  };

  toggleHandler = (id, lock_status) => {
    if (lock_status == true && !this.props.loading) {
      this.props.enableLock(`enable/users/${id}`, this.props.token);
    } else {
      this.props.disableLock(`disable/user/${id}`, this.props.token);
    }
    // this.props.enableLock(`enable/users/${id}`, this.props.token);
  };

  showModal = (e) => {
    e.preventDefault();
    this.setState({
      display: true,
    });
  };

  closeModal = () => {
    this.setState({
      display: false,
      add_modal: false,
      upload_modal: false,
      claim_modal: false,
    });
  };

  onSubmit = (data) => {
    this.props.filterAllUsers(data, this.props.token);
  };

  addNew = () => {
    this.setState({
      add_modal: true,
    });
  };

  addUser = (data) => {
    if (data.password != data.c_password) {
      alert("password don't match");
    } else {
      callBackMethod(
        this.props.agentAddUsers(
          "user/users/register/web",
          data,
          this.props.token
        )
      )
        .then(() => alert(this.props.add_user_resp))
        .then(() => this.setState({ add_modal: false }))
        .then(() =>
          this.props.getUsers(
            this.props.token,
            `user/get_users/sales_agents/${this.props.agent_id}`
          )
        );
    }
  };

  uploadDocsModal = (props) => {
    this.setState({
      ...this.state,
      user_data: props,
      upload_modal: true,
    });
  };

  uploadUserDoc = (formData) => {
    this.props.agentUploadUserDoc(
      "user/user_documents",
      formData,
      this.props.token
    );
  };

  addClaim = () => {
    this.setState({
      claim_modal: true,
    });
  };

  claimUser = (data) => {
    callBackMethod(
      this.props.addAgentClaim(
        "user/sales_agent_claims",
        data,
        this.props.token
      )
    )
      .then(() => this.setState({ claim_modal: false }))
      .then(() => alert(this.props.agent_claim_resp));
  };

  render() {
    let renderPaginate;
    let itemInTable;
    const total = parseInt(Math.ceil(this.props.total / 15));
    const paginateNumber = Array(15).fill(0);
    this.props.total > 15 &&
      !this.props.loading &&
      (renderPaginate = (
        <Pagination
          paginateNumber={paginateNumber}
          currentPage={this.state.currentPage}
          handlePagination={this.changePage}
          handlePrevious={this.goPrevious}
          handleNext={this.goNext}
          total={total}
          totalData={this.props.total}
        />
      ));
    if (this.props.loading) {
      itemInTable = <Loader loading={this.props.loading} />;
    } else {
      itemInTable = (
        <>
          <TableHead />
          <tbody>
            {this.props.users &&
              this.props.users.map((user, index) => (
                <Row
                  {...user}
                  goToDetails={this.handleNextPage}
                  index={this.getIndex(index + 1)}
                  toggleHandler={this.toggleHandler}
                  uploadDocs={this.uploadDocsModal}
                />
              ))}
          </tbody>
        </>
      );
    }
    return (
      <>
        <div>
          <SideBar />
          <div class="main-content" id="panel">
            <DashboardNavBar
              submenu="All Users"
              currentPage={`user/users-all?page=${this.state.currentPage}`}
              getInfo={this.props.getUsers}
              search={this._searchField}
              shouldFilter={true}
              showModal={this.showModal}
              addNew={this.addNew}
              addClaim={this.addClaim}
            />
            <div class="container-fluid mt--6">
              <div class="row">
                <div class="col">
                  <div class="card">
                    <div class="card-header">
                      <h3 class="mb-0">All Customers</h3>
                    </div>
                    <div className="table-responsive py-4">
                      <table className="table table-flush" id="datatable-basic">
                        {itemInTable}
                      </table>
                      {renderPaginate}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <UserFilterModal
          display={this.state.display}
          closeModal={this.closeModal}
          submit={this.onSubmit}
        />
        <AddUserModal
          display={this.state.add_modal}
          closeModal={this.closeModal}
          handleSubmit={this.addUser}
        />
        <UploadDocModal
          display={this.state.upload_modal}
          closeModal={this.closeModal}
          user_id={this.state.user_data}
          uploadUserDoc={this.uploadUserDoc}
        />
        <ClaimModal
          display={this.state.claim_modal}
          closeModal={this.closeModal}
          addClaim={this.claimUser}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: state.users.allusers.loading,
  users: state.users.allusers.usersData,
  total: state.users.allusers.total,
  err: state.users.allusers.failed,
  token: state.login.token,
  response_msg: state.users.allusers.response_msg,
  filtering: state.users.allusers.filtering,
  agent_id: state.login.data?.sales_agent_id,
  add_user_resp: state.users.allusers.add_user_resp,
  agent_claim_resp: state.users.allusers.agent_claim_resp,
});
export default connect(mapStateToProps, {
  getUsers,
  detailsInfo,
  tabDetail,
  filterUserTable,
  enableLock,
  disableLock,
  filterAllUsers,
  logOut,
  agentAddUsers,
  getKycLevel,
  agentUploadUserDoc,
  addAgentClaim,
})(AllUsers);
