import { LOGIN_SUCCESS, LOGIN_FAIL, LOGOUT } from "../action/action.types";

const initialState = {
  token: "",
  isLoggedIn: false,
  loading: false,
  loginError: "",
};

export const loginReducer = (state = initialState, action) => {
  switch (action.type) {
    case "LOADING":
      return {
        ...state,
        loading: true,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        token: action.payload.token,
        isLoggedIn: action.payload.success,
        permission: action.payload.permission,
        data: action.payload.data,
        loading: false,
      };
    case LOGIN_FAIL:
      return {
        ...state,
        isLoggedIn: false,
        loading: false,
        loginError: action.payload,
      };
    case LOGOUT:
      return {
        ...state,
        isLoggedIn: false,
        loading: false,
        token: "",
      };
    default:
      return state;
  }
};
