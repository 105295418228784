import { combineReducers } from "redux";
import {
  DELETE_BATCH,
  GET_BATCH,
  GET_BATCH_REQUEST,
  GET_CARD_REQUEST,
  SET_BATCH_REQUEST,
} from "../action/action.types";

const cardBatchReducer = (state = {}, action) => {
  switch (action.type) {
    case "LOADING":
      return {
        ...state,
        loading: true,
      };
    case GET_BATCH:
      return {
        ...state,
        loading: false,
        batches: action.payload.data,
      };
    case GET_CARD_REQUEST:
      return {
        ...state,
        loading: false,
        request: action.payload.data,
      };
    case GET_BATCH_REQUEST:
      return {
        ...state,
        loading: false,
        batch_request: action.payload.data,
      };
    case DELETE_BATCH:
      return {
        ...state,
        loading: false,
        del_message: action.payload.Response_message,
      };
    default:
      return state;
  }
};

const addBatchRequestReducer = (state = {}, action) => {
  switch (action.type) {
    case SET_BATCH_REQUEST:
      return {
        ...state,
        data: action.payload.data,
      };
    default:
      return state;
  }
};

export const cardReducer = combineReducers({
  all_batches: cardBatchReducer,
  set_req_batch: addBatchRequestReducer,
});
