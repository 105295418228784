import React from "react";
import { connect } from "react-redux";
import { LoaderTwo } from "../../components/Accessories";
import { adminLogin } from "../../reduxStore/action/action.creator";

class Login extends React.Component {
  state = {
    email: "",
    password: "",
    formReady: false,
  };

  componentDidMount() {
    if (this.props.token != "") {
      this.props.history.push("/home");
    }
    return;
  }

  componentDidUpdate(prevProps, prevState) {
    Object.keys(this.state).map((key) => {
      if (key != "formReady" && key != "isLoading") {
        if (prevState[key] != this.state[key]) {
          this.validateForm();
        }
      }
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.goToDashboard(nextProps);
  }

  goToDashboard = (nextProps) => {
    if (nextProps.token) {
      this.props.history.push("/home");
    }

    if (nextProps.loading) {
      this.setState({
        formReady: false,
      });
    } else {
      this.setState({
        formReady: true,
      });
    }
  };

  validateForm = () => {
    Object.keys(this.state).map((key) => {
      if (key !== "formReady" && key != "isLoading") {
        if (this.state[key].length > 0) {
          this.setState({
            formReady: true,
          });
        } else {
          this.setState({
            formReady: false,
          });
        }
      }
    });
  };

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  handleSubmit = async () => {
    if (this.state.formReady) {
      this.props.adminLogin(this.state);
    }
  };

  render() {
    return (
      <body className="bg-default">
        <div className="main-content">
          <div
            className="header py-6 py-lg-6 pt-lg-8"
            style={{ backgroundColor: "#0038ba" }}>
            <div className="separator separator-bottom separator-skew zindex-100">
              <svg
                x="0"
                y="0"
                viewBox="0 0 2560 100"
                preserveAspectRatio="none"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg">
                <polygon
                  className="fill-default"
                  points="2560 0 2560 100 0 100"></polygon>
              </svg>
            </div>
          </div>
          <div className="container mt--8 pb-5">
            <div className="row justify-content-center">
              <div className="col-lg-5 col-md-7">
                <div className="card bg-secondary border-0 mb-0">
                  <div className="card-header bg-transparent">
                    <div className="text-muted text-center mt-2 mb-3">
                      <small>Welcome Admin</small>
                    </div>
                    <div
                      className="btn-wrapper text-center"
                      style={{
                        height: "5em",
                      }}>
                      <img
                        src="../../assets/img/brand/getripay.png"
                        height="100%"
                      />
                    </div>
                  </div>
                  <div className="card-body px-lg-4.5 py-lg-4.5">
                    <form role="form">
                      <div className="form-group mb-3">
                        <div className="input-group input-group-merge input-group-alternative">
                          <div className="input-group-prepend">
                            <span className="input-group-text">
                              <i className="ni ni-email-83"></i>
                            </span>
                          </div>
                          <input
                            className="form-control"
                            placeholder="Email"
                            type="email"
                            onChange={this.handleChange}
                            name="email"
                            value={this.state.email}
                            autoComplete="off"
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="input-group input-group-merge input-group-alternative">
                          <div className="input-group-prepend">
                            <span className="input-group-text">
                              <i className="ni ni-lock-circle-open"></i>
                            </span>
                          </div>
                          <input
                            className="form-control"
                            placeholder="Password"
                            type="password"
                            name="password"
                            value={this.state.password}
                            onChange={this.handleChange}
                          />
                        </div>
                      </div>
                      <div className="custom-control custom-control-alternative custom-checkbox">
                        <input
                          className="custom-control-input"
                          id=" customCheckLogin"
                          type="checkbox"
                        />
                        <label
                          className="custom-control-label"
                          for=" customCheckLogin">
                          <span className="text-muted">Remember me</span>
                        </label>
                      </div>
                      <div className="text-center">
                        <button
                          type="button"
                          class="btn btn-primary my-4"
                          onClick={this.handleSubmit}
                          disabled={!this.state.formReady ? true : false}>
                          {!this.props.loading ? "Sign In" : <LoaderTwo />}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </body>
    );
  }
}

const mapStateToProps = (state) => ({
  success: state.login.isLoggedIn,
  token: state.login.token,
  error: state.login.loginError,
  loading: state.login.loading,
  permission: state.login.permission,
});
export default connect(mapStateToProps, { adminLogin })(Login);
