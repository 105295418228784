import {
  SET_ROLE,
  GET_ROLE,
  SET_PERMISSION,
  GET_PERMISSION,
  SET_PERMISSION_MODULE,
  GET_PERMISSION_MODULE,
  SET_ADMIN_PERMISSION,
  GET_ADMIN_PERMISSION,
} from "../action/action.types";
import { combineReducers } from "redux";
import { setAdminPermission } from "../../API";

const setroleReducer = (state = {}, action) => {
  switch (action.type) {
    case SET_ROLE:
      return {
        ...state,
        success: action.payload.success,
        response_msg: action.payload.response_message,
      };
    default:
      return state;
  }
};

const getroleReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_ROLE:
      return {
        ...state,
        data: action.payload.data,
      };
    default:
      return state;
  }
};

const setPermission = (state = {}, action) => {
  switch (action.type) {
    case SET_PERMISSION:
      return {
        ...state,
        success: action.payload.success,
        response_message: action.payload.response_message,
      };
    default:
      return state;
  }
};

const getpermissionReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_PERMISSION:
      return {
        ...state,
        data: action.payload.data,
      };
    default:
      return state;
  }
};

const setPermissionModReducer = (state = {}, action) => {
  switch (action.type) {
    case SET_PERMISSION_MODULE:
      return {
        ...state,
        success: action.payload.success,
      };
    default:
      return state;
  }
};

const getPermissionModReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_PERMISSION_MODULE:
      return {
        ...state,
        data: action.payload.data,
      };
      break;
    default:
      return state;
  }
};

const setAdminPermissionReducer = (state = {}, action) => {
  switch (action.type) {
    case SET_ADMIN_PERMISSION:
      return {
        ...state,
        success: action.payload.success,
        response: action.payload.response_message,
      };
    default:
      return state;
  }
};

const getAdminPermissionReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_ADMIN_PERMISSION:
      return {
        ...state,
        data: action.payload.data,
      };
    default:
      return state;
  }
};

export const initialRoleReducer = combineReducers({
  setrole: setroleReducer,
  getrole: getroleReducer,
  setpermission: setPermission,
  getpermission: getpermissionReducer,
  setpermissionmod: setPermissionModReducer,
  getpermissionmod: getPermissionModReducer,
  setadminpermission: setAdminPermissionReducer,
  getadminpermission: getAdminPermissionReducer,
});
