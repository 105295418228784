import { combineReducers } from "redux";
import {
  FILTER_NOTIFICATION,
  GET_CHANNEL,
  GET_NOTIFICATION,
  GET_NOTIFICATION_MODE,
  GET_NOTIFICATION_STATUS,
  GET_NOTIFICATION_TYPES,
  TOGGLE_CHANNEL,
} from "../action/action.types";

const initialState = {
  toggle_msg: "",
};

const notificationReducer = (state = {}, action) => {
  switch (action.type) {
    case "LOADING":
      return {
        ...state,
        loading: true,
      };
    case GET_NOTIFICATION:
      return {
        ...state,
        loading: false,
        notification: action.payload.notifications,
        total: action.payload.allNotifications,
      };
    case FILTER_NOTIFICATION:
      return {
        ...state,
        loading: false,
        notification: action.payload.notifications,
        total: action.payload.allNotifications,
      };
    case GET_NOTIFICATION_STATUS:
      return {
        ...state,
        status_data: action.payload.data,
      };
    case GET_NOTIFICATION_TYPES:
      return {
        ...state,
        type_data: action.payload.data,
      };
    case GET_NOTIFICATION_MODE:
      return {
        ...state,
        mode_data: action.payload.data,
      };
    default:
      return state;
  }
};

const channelsReducer = (state = {}, action) => {
  switch (action.type) {
    case "LOADING":
      return {
        ...state,
        loading: true,
      };
    case GET_CHANNEL:
      return {
        ...state,
        loading: false,
        channels: action.payload.channels,
      };
    case TOGGLE_CHANNEL:
      return {
        ...state,
        toggle_msg: action.payload.response_description,
      };
    default:
      return state;
  }
};

export const initialNotification = combineReducers({
  allnotification: notificationReducer,
  notificationchannels: channelsReducer,
});
