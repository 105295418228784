export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const USER_SUCCESS = "USER_SUCCESS";
export const USER_FAILED = "USER_FAILED";
export const ADMIN_SUCCESS = "ADMIN_SUCCESS";
export const ADMIN_FAILED = "ADMIN_FAILED";
export const TRANSACTION_SUCCESS = "TRANSACTION_SUCCESS";
export const TRANSACTION_FAILED = "TRANSACTION_FAILED";
export const LOGOUT = "LOGOUT";
export const DETAIL_INFO = "DETAIL_INFO";
export const FEE_PROFILE_SUCCESS = "FEE_PROFILE_SUCCESS";
export const FEE_PROFILE_FAILED = "FEE_PROFILE_FAILED";
export const TRANS_CAT_SUCCESS = "TRANS_CAT_SUCCESS";
export const TRANS_CAT_FAILED = "TRANS_CAT_FAILED";
export const TRANS_SERVICE_CODE_SUCCESS = "TRANS_SERVICE_CODE_SUCCESS";
export const TRANS_SERVICE_CODE_FAILED = "TRANS_SERVICE_CODE_FAILED";
export const SET_FEE_PROFILE_SUCCESS = "SET_FEE_PROFILE_SUCCESS";
export const SET_FEE_PROFILE_FAILED = "SET_FEE_PROFILE_FAILED";
export const GET_BEN_SUCCESS = "GET_BEN_SUCCESS";
export const GET_BEN_FAILED = "GET_BEN_FAILED";
export const SET_BENEFICIARY_SUCCESS = "SET_BENEFICIARY_SUCCESS";
export const SET_BENEFICIARY_FAILURE = "SET_BENEFICIARY_FAILURE";
export const GET_COMMISSION_SUCCESS = "GET_COMMISSION_SUCCESS";
export const GET_COMMISSION_FAILED = "GET_COMMISSION_FAILED";
export const SET_COMMISSION_SUCCESS = "SET_COMMISSION_SUCCESS";
export const SET_COMMISSION_FAILED = "SET_COMMISSION_FAILED";
export const GET_DETAIL_SUCCESS = "GET_DETAIL_SUCCESS";
export const TRANSACTION_DETAIL_INFO = "TRANSACTION_DETAIL_INFO";
export const GET_DETAIL_FAILED = "GET_DETAIL_FAILED";
export const GET_LOAN_PRODUCTS_FAILED = "GET_LOAN_PRODUCTS_FAILED";
export const GET_LOAN_PRODUCTS_SUCCESS = "GET_LOAN_PRODUCTS_SUCCESS";
export const GET_LOAN_REQUEST_FAILED = "GET_LOAN_REQUEST_FAILED";
export const GET_LOAN_REQUEST_SUCCESS = "GET_LOAN_REQUEST_SUCCESS";
export const APPROVE_LOAN_SUCCESS = "APPROVE_LOAN_SUCCESS";
export const DISBURSE_SUCCESS = "DISBURSE_SUCCESS";
export const DELETED_INFO = "DELETED_INFO";
export const GET_TXN_LIMIT_CONFIGS_SUCCESS = "GET_TXN_LIMIT_CONFIGS_SUCCESS";
export const GET_TXN_LIMIT_CONFIGS_FAILED = "GET_TXN_LIMIT_CONFIGS_FAILED";
export const SET_TXN_LIMIT_SUCCESS = "SET_TXN_LIMIT_SUCCESS";
export const SET_TXN_LIMIT_FAILED = "SET_TXN_LIMIT_FAILED";
export const GET_USER_LOAN = "GET_USER_LOAN";
export const GET_PARTNER_SUCCESS = "GET_PARTNER_SUCCESS";
export const TOGGLE_SUCCESS = "TOGGLE_SUCCESS";
export const FILTER_SUCCESS = "FILTER_SUCCESS";
export const SET_PROMO = "SET_PROMO";
export const GET_PROMO = "GET_PROMO";
export const DELETE_PROMO = "DELETE PROMO";
export const TOGGLE_PROMO = "TOGGLE_PROMO";
export const SET_ROLE = "SET_ROLE";
export const GET_ROLE = "GET_ROLE";
export const SET_PERMISSION = "SET_PERMISSION";
export const GET_PERMISSION = "GET_PERMISSION";
export const SET_PERMISSION_MODULE = "SET_PERMISSION_MODULE";
export const GET_PERMISSION_MODULE = "GET_PERMISSION_MODULE";
export const SET_ADMIN_PERMISSION = "SET_ADMIN_PERMISSION";
export const GET_ADMIN_PERMISSION = "GET_ADMIN_PERMISSION";
export const DELETE_PERMISSION_LIST = "DELETE_PERMISSION_LIST";
export const GET_BANK_TRANS = "GET_BANK_TRANS";
export const GET_FLUTTER = "GET_FLUTTER";
export const DELETE_ADMIN = "DELETE_ADMIN";
export const FILTER_BANK_TRANS = "FILTER_BANK_TRANS";
export const FILTER_FLUTTER = "FILTER_FLUTTER";
export const GET_BILL_LIST = "GET_BILL_LIST";
export const GET_BILL_TRANS = "GET_BILL_TRANS";
export const GET_BANK_TRANS_STATUS = "GET_BANK_TRANS_STATUS";
export const GET_FLW_RANGE = "GET_FLW_RANGE";
export const GET_NOTIFICATION = "GET_NOTIFICATION";
export const FILTER_NOTIFICATION = "FILTER_NOTIFICATION";
export const GET_CHANNEL = "GET_CHANNEL";
export const TOGGLE_CHANNEL = "TOGGLE_CHANNEL";
export const GET_NOTIFICATION_STATUS = "GET_NOTIFICATION_VALUE";
export const GET_NOTIFICATION_TYPES = "GET_NOTIFICATION_TYPES";
export const GET_NOTIFICATION_MODE = "GET_NOTIFICATION_MODE";
export const FILTER_TRANSACTION_STATUS = "FILTER_TRANSACTION_STATUS";
export const SET_REQUERY = "SET_REQUERY";
export const SET_REVERSAL = "SET_REVERSAL";
export const ENABLE_USER = "ENABLE_USER";
export const DISABLE_USER = "DISABLE_USER";
export const UPDATE_ADMIN = "UPDATE_ADMIN";
export const FILTER_TRANSACTION = "FILTER_TRANSACTION";
export const FILTER_USERS = "FILTER_USERS";
export const USER_REPORT_INFO = "USER_REPORT_INFO";
export const TRANSACTION_REPORT = "TRANSACTION_REPORT";
export const ACCOUNT_BALANCE = "ACCOUNT_BALANCE";
export const ACCOUNT_STATEMENT = "ACCOUNT_STATEMENT";
export const NEW_RANGE = "NEW_RANGE";
export const FILTER_USER_REPORT = "FILTER_USER_REPORT";
export const REPORT_BY_INSTITUTE = "REPORT_BY_INSTITUTE";
export const GET_INSTITUTIONS = "GET_INSTITUTIONS";
export const FILTER_TRANS_REPORT_YEAR = "FILTER_TRANS_REPORT_YEAR";
export const FILTER_TRANS_REPORT_INSTITUTE = "FILTER_TRANS_REPORT_INSTITUTE";
export const GET_INSTITUTE_STATUS = "GET_INSTITUTE_STATUS";
export const FILTER_INSTITUTE_REPORT = "FILTER_INSTITUTE_REPORT";
export const GET_SERVICE_REPORT = "GET_SERVICE_REPORT";
export const GET_TRANSACTION_SERVICE = "GET_TRANSACTION_SERVICE";
export const EDIT_PROMOTION = "EDIT_PROMOTION";
export const EDIT_LIMIT = "EDIT_LIMIT";
export const DELETE_TRANSACTION_LIMIT = "DELETE_TRANSACTION_LIMIT";
export const SERVICE_APPLICABLE = "SERVICE_APPLICABLE";
export const GET_SUBSCRIBERS = "GET_SUBSCRIBERS";
export const GET_PROMO_SUBSCRIBERS = "GET_PROMO_SUBSCRIBERS";
export const ADD_BATCH = "ADD_BATCH";
export const GET_BATCH = "GET_BATCH";
export const GET_CARD_REQUEST = "GET_CARD_REQUEST";
export const DELETE_BATCH = "DELETE_BATCH";
export const SET_BATCH_REQUEST = "SET_BATCH_REQUEST";
export const GET_BATCH_REQUEST = "GET_BATCH_REQUEST";
export const GET_DOCUMENT = "GET_DOCUMENT";
export const SET_LEVEL = "SET_LEVEL";
export const GET_LEVEL = "GET_LEVEL";
export const SET_LEVEL_SETTINGS = "SET_LEVEL_SETTINGS";
export const GET_LEVEL_SETTINGS = "GET_LEVEL_SETTINGS";
export const SET_REQUIREMENT = "SET_REQUIREMENT";
export const GET_REQUIREMENT = "GET_REQUIREMENT";
export const APPROVE_DOCUMENT = "APPROVE_DOCUMENT";
export const REJECT_DOCUMENT = "REJECT_DOCUMENT";
export const LOAD_HISTORY = "LOAD_HISTORY";
export const LOAN_DETAILS = "LOAN_DETAILS";
export const KYC_LEVEL_DETAIL = "KYC_LEVEL_DETAIL";
export const DELETE_KYC_LEVEL = "DELETE_KYC_LEVEL";
export const AUDIT_LOGS_SUCCESS = "AUDIT_LOGS_SUCCESS";
export const AUDIT_LOGS_FAILED = "AUDIT_LOGS_FAILED";
export const EDIT_KYC_LEVEL = "EDIT_KYC_LEVEL";
export const FINAL_APPROVAL = "FINAL_APPROVAL";
export const DELETE_REQUIREMENT = "DELETE_REQUIREMENT";
export const SET_RATE = "SET_RATE";
export const GET_RATE = "GET_RATE";
export const GET_A_RATE = "GET_A_RATE";
export const SET_KYC_VIRTUAL_CARD = "SET_KYC_VIRTUAL_CARD";
export const GET_KYC_VIRTUAL_CARD = "GET_KYC_VIRTUAL_CARD";
export const SET_VIRTUAL_LIMIT = "SET_VIRTUAL_LIMIT";
export const GET_VIRTUAL_LIMIT = "GET_VIRTUAL_LIMIT";
export const GET_VIRTUAL_TRANS = "GET_VIRTUAL_TRANS";
export const GET_VIRTUAL_CARD = "GET_VIRTUAL_CARD";
export const GET_CURRENCIES = "GET_CURRENCIES";
export const SWITCH_BILL = "SWITCH_BILL";
export const LOCK_CARD = "LOCK_CARD";
export const UNLOCK_CARD = "UNLOCK_CARD";
export const FILTER_DOCUMENT = "FILTER_DOCUMENT";
export const DEVELOPER_ACCOUNTS_SUCCESS = "AUDIT_LOGS_SUCCESS";
export const DEVELOPER_ACCOUNTS_FAILED = "AUDIT_LOGS_FAILED";
export const DEVELOPER_CALLS_SUCCESS = "DEVELOPER_CALLS_SUCCESS";
export const DEVELOPER_CALLS_FAILED = "DEVELOPER_CALLS_FAILED";
export const DEVELOPER_CALL_STATS_SUCCESS = "DEVELOPER_CALL_STATS_SUCCESS";
export const DEVELOPER_CALL_STATS_FAILED = "DEVELOPER_CALL_STATS_FAILED";
export const ADD_USER_BY_AGENT = "ADD_USER_BY_AGENT";
export const ADD_USER_BY_AGENT_FAIL = "ADD_USER_BY_AGENT_FAIL";
export const UPLOAD_USER_DOCS = "UPLOAD_USER_DOCS";
export const ADD_AGENT_CLAIM = "ADD_AGENT_CLAIM";
