import {
  GET_LOAN_PRODUCTS_SUCCESS,
  GET_LOAN_PRODUCTS_FAILED,
  GET_LOAN_REQUEST_SUCCESS,
  GET_LOAN_REQUEST_FAILED,
} from "../action/action.types";
import { combineReducers } from "redux";
const initialState = {
  loanProducts: [],
  loanRequests: [],
  adminData: [],
  bendata: [],
};

const allLoanProductsReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SENDING":
      console.log("loading");
      return {
        ...state,
        loading: true,
      };
    case GET_LOAN_PRODUCTS_SUCCESS:
      console.log("all loan", action.payload.allLoanProducts);
      return {
        ...state,
        loanProducts: action.payload.allLoanProducts,
        loading: false,
      };
    case GET_LOAN_PRODUCTS_FAILED:
      return {
        ...state,
        failed: action.payload,
      };
    default:
      return state;
  }
};

const allLoanRequestsReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SENDING":
      console.log("loading");
      return {
        ...state,
        loading: true,
      };
    case GET_LOAN_REQUEST_SUCCESS:
      console.log("all loan requests", action.payload.allLoanRequests);
      return {
        ...state,
        loanRequests: action.payload.allLoanRequests,
        total: action.payload.total,
        loading: false,
      };
    case GET_LOAN_REQUEST_FAILED:
      return {
        ...state,
        failed: action.payload,
      };
    default:
      return state;
  }
};

export const loanReducer = combineReducers({
  allLoanProducts: allLoanProductsReducer,
  allLoanRequests: allLoanRequestsReducer,
});
