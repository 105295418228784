import React from "react";
import { connect } from "react-redux";
import SideBar from "../../components/SideBar";
import DashboardNavBar from "../../components/DashboardNavbar";
import "../../morestyles.css";
import { changePass } from "../../API/AuthRequest";
import { callBackMethod } from "../../components/Accessories";

class AdminProfile extends React.Component {
  state = {
    old_password: "",
    new_password: "",
    c_new_password: "",
    sales_agent_id: this.props.agent_id,
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleSubmit = async () => {
    if (this.state.c_new_password === this.state.new_password) {
      // this.props.changePassword(this.state);
      try {
        const res = await changePass(this.state, this.props.token);
        if (res.success) {
          callBackMethod(alert(res.response_message)).then(() =>
            this.setState({
              old_password: "",
              new_password: "",
              c_new_password: "",
            })
          );
        }
      } catch (error) {}
    } else {
      alert("wassa");
    }
  };

  render() {
    return (
      <div>
        <SideBar />
        <div class="main-content" id="panel">
          <DashboardNavBar />
          <div class="container-fluid mt--6">
            <div class="row">
              <div class="col">
                <div class="card">
                  <div class="card-header">
                    <h3 class="mb-0">Admin Profile</h3>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div class="col-6 userDetailsCol1">First View</div>
                      <div class="col-6 userDetailsCol2 px-2">
                        <div class="nav-wrapper">
                          <ul
                            class="nav nav-pills nav-fill flex-column flex-md-row"
                            id="tabs-icons-text"
                            role="tablist">
                            <li class="nav-item">
                              <a
                                class="nav-link mb-sm-3 mb-md-0 active"
                                id="tabs-icons-text-1-tab"
                                data-toggle="tab"
                                href="#tabs-icons-text-1"
                                role="tab"
                                aria-controls="tabs-icons-text-1"
                                aria-selected="true">
                                <i class="ni ni-cloud-upload-96 mr-2"></i>Change
                                Password
                              </a>
                            </li>
                            <li class="nav-item">
                              <a
                                class="nav-link mb-sm-3 mb-md-0"
                                id="tabs-icons-text-2-tab"
                                data-toggle="tab"
                                href="#tabs-icons-text-2"
                                role="tab"
                                aria-controls="tabs-icons-text-2"
                                aria-selected="false">
                                <i class="ni ni-bell-55 mr-2"></i>Profile
                              </a>
                            </li>
                            <li class="nav-item">
                              <a
                                class="nav-link mb-sm-3 mb-md-0"
                                id="tabs-icons-text-3-tab"
                                data-toggle="tab"
                                href="#tabs-icons-text-3"
                                role="tab"
                                aria-controls="tabs-icons-text-3"
                                aria-selected="false">
                                <i class="ni ni-calendar-grid-58 mr-2"></i>
                                Messages
                              </a>
                            </li>
                          </ul>
                        </div>
                        <div class="card shadow">
                          <div class="card-body">
                            <div class="tab-content" id="myTabContent">
                              <div
                                class="tab-pane fade show active"
                                id="tabs-icons-text-1"
                                role="tabpanel"
                                aria-labelledby="tabs-icons-text-1-tab">
                                <div class="form-group">
                                  <label for="exampleFormControlInput1">
                                    Enter Old Password
                                  </label>
                                  <input
                                    type="password"
                                    class="form-control"
                                    name="old_password"
                                    value={this.state.old_password}
                                    onChange={this.handleChange}
                                    id="exampleFormControlInput1"
                                    placeholder="****"
                                  />
                                </div>
                                <div class="form-group">
                                  <label for="exampleFormControlInput1">
                                    New Password
                                  </label>
                                  <input
                                    type="password"
                                    name="new_password"
                                    value={this.state.new_password}
                                    class="form-control"
                                    onChange={this.handleChange}
                                    id="exampleFormControlInput1"
                                  />
                                </div>
                                <div class="form-group">
                                  <label for="exampleFormControlInput1">
                                    Confirm Password
                                  </label>
                                  <input
                                    type="password"
                                    name="c_new_password"
                                    value={this.state.c_new_password}
                                    class="form-control"
                                    onChange={this.handleChange}
                                    id="exampleFormControlInput1"
                                  />
                                </div>
                                <div
                                  className="col-md-12"
                                  style={{ paddingTop: "1em" }}>
                                  <div className="text-center">
                                    <button
                                      type="button"
                                      class="btn btn-primary"
                                      onClick={this.handleSubmit}>
                                      Submit Change
                                    </button>
                                  </div>
                                </div>
                              </div>
                              <div
                                class="tab-pane fade"
                                id="tabs-icons-text-2"
                                role="tabpanel"
                                aria-labelledby="tabs-icons-text-2-tab"></div>
                              <div
                                class="tab-pane fade"
                                id="tabs-icons-text-3"
                                role="tabpanel"
                                aria-labelledby="tabs-icons-text-3-tab"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  agent_id: state.login.data?.id,
  token: state.login.token,
});

export default connect(mapStateToProps, {})(AdminProfile);
