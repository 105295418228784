import React from "react";
import { connect } from "react-redux";
import { Dashboard } from "../pages/DashBoard";
import { AdminProfile } from "../pages/ProfilePage";
import { Report } from "../pages/Report";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { AllUserRoute } from "../pages/AllUsers";
import { TransactionRoute } from "../pages/Transaction";
import { Error } from "../pages/Error";
import {
  getPermissionMod,
  getUsers,
  logOut,
} from "../reduxStore/action/action.creator";
import { LogOutModal } from "../components/Accessories";

class Routes extends React.Component {
  state = {
    isAllowed: [],
    display: false,
    countDown: 10,
  };

  componentWillReceiveProps(nextProps) {
    // if (nextProps.err) {
    //   alert(nextProps.err);
    // }
  }

  closeModal = () => {
    this.setState({
      display: false,
    });
  };

  render() {
    return (
      <>
        <LogOutModal
          display={this.state.display}
          closeModal={this.closeModal}
        />
        <Switch>
          <Route exact path="/home" component={Dashboard} />
          <Route exact path="/profile" component={AdminProfile} />
          {/* {this.state.isAllowed.includes("Customer") && ( */}
          <Route path="/allusers" component={AllUserRoute} />
          {/* )} */}

          <Route
            path="/transactions"
            render={(props) => <TransactionRoute {...props} />}
          />
          <Route exact component={Error} />
        </Switch>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  permission: state.login.permission,
  username: state.login.data.username,
  data: state.roles.getpermissionmod.data,
  err: state.users.allusers.failed,
  token: state.login.token,
});
export default connect(mapStateToProps, { getPermissionMod, getUsers, logOut })(
  Routes
);
