import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Transaction from "./Transaction";
import TransactionDetails from "./TransactionDetails";

const TransactionRoute = ({ match }) => (
  <>
    <Switch>
      <Route exact path={`${match.path}/`} component={Transaction} />
      <Route
        exact
        path={`${match.path}/:name/details`}
        component={TransactionDetails}
      />
    </Switch>
  </>
);
export default TransactionRoute;
