import React from "react";
import { connect } from "react-redux";
import SideBar from "../../components/SideBar";
import DashboardNavBar from "../../components/DashboardNavbar";
import { SummaryData, dailyBillTrans } from "../../API/ReportRequest";
import { Link } from "react-router-dom";
import { SingleCard } from "../../components/Accessories";
import "../../morestyles.css";

class Report extends React.Component {
  state = {
    totalTrans: [],
    totalCommissions: [],
    totalUserSum: [],
    totalNotification: [],
  };

  componentDidMount() {
    this.getTotalTransaction();
  }

  getTotalTransaction = async (date) => {
    try {
      const {
        summaryArray,
        commissionArray,
        userArray,
        notification,
      } = await SummaryData(date, this.props.token);
      this.setState((prevState) => ({
        totalTrans: summaryArray,
        totalCommissions: commissionArray,
        totalUserSum: userArray,
        totalNotification: notification,
      }));
    } catch (error) {
      console.error(error);
    }
  };

  render() {
    return (
      <div>
        <SideBar />
        <div class="main-content" id="panel">
          <DashboardNavBar
            submenu="Report Summary"
            report={true}
            handleReport={this.getTotalTransaction}
          />
          <div class="container-fluid mt--6">
            <div class="row align-items-center py-4">
              <div class="col">
                <div class="card bg-primary">
                  <div
                    class="card-header"
                    style={{ backgroundColor: "#E6E6FA" }}>
                    <div className="row">
                      <div className="col-md-6">
                        <span
                          style={{ fontSize: "18px", fontWeight: "600" }}
                          contentEditable>
                          User Management Summary Reports
                        </span>
                      </div>
                      <div className="col-md-6">
                        <div className="text-right">
                          <Link
                            to={`${this.props.match.url}/user-report`}
                            class="btn btn-sm btn-neutral">
                            View Report Summary
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="card-body">
                    <div className="row">
                      {this.state.totalUserSum.map((content) => (
                        <SingleCard {...content} />
                      ))}
                    </div>
                  </div>
                </div>

                <div class="card bg-primary">
                  <div
                    class="card-header"
                    style={{ backgroundColor: "#E6E6FA" }}>
                    <div className="row">
                      <div className="col-md-6">
                        <span style={{ fontSize: "18px", fontWeight: "600" }}>
                          Transaction Summary Reports
                        </span>
                      </div>
                      <div className="col-md-6">
                        <div className="text-right">
                          <Link
                            to={`${this.props.match.url}/transaction-report`}
                            class="btn btn-sm btn-neutral">
                            View Report Summary
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="card-body">
                    <div className="row">
                      {this.state.totalTrans.map((content, index) => (
                        <SingleCard isTransaction={true} {...content} />
                      ))}
                    </div>
                  </div>
                </div>

                <div class="card bg-primary">
                  <div
                    class="card-header"
                    style={{ backgroundColor: "#E6E6FA" }}>
                    <span style={{ fontSize: "18px", fontWeight: "600" }}>
                      Commissions / Fees Summary Reports
                    </span>
                  </div>
                  <div class="card-body">
                    <div className="row">
                      {this.state.totalCommissions.map((content, index) => (
                        <SingleCard {...content} />
                      ))}
                    </div>
                  </div>
                </div>

                <div class="card bg-primary">
                  <div
                    class="card-header"
                    style={{ backgroundColor: "#E6E6FA" }}>
                    <span style={{ fontSize: "18px", fontWeight: "600" }}>
                      Notification Report
                    </span>
                  </div>
                  <div class="card-body">
                    <div className="row">
                      {this.state.totalNotification.map((content, index) => (
                        <SingleCard {...content} />
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  token: state.login.token,
});
export default connect(mapStateToProps, {})(Report);
