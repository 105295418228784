import React from "react";
import SideBar from "../../../components/SideBar";
import DashboardNavBar from "../../../components/DashboardNavbar";
import "../../../morestyles.css";
import {
  UserReportTableHead,
  UserReportTableBody,
} from "../../../components/Rows/ReportTable";
import {
  getUserReport,
  filterReport,
} from "../../../reduxStore/action/action.creator";
import { connect } from "react-redux";
import { Loader, LoaderTwo } from "../../../components/Accessories";

class UserReport extends React.Component {
  state = {
    year: "",
    csvReport: {
      data: [],
      filename: "some.csv",
    },
  };

  componentDidMount() {
    this.props.getUserReport(this.props.token);
  }

  handleRefresh = (e) => {
    this.props.getUserReport(this.props.token);
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  filterUserReport = () => {
    this.props.filterReport(this.state, this.props.token);
  };

  exportCSV = (event, done) => {
    const data = Object.keys(this.props.user_report).map((key) => {
      this.props.user_report[key].month = key;
      return this.props.user_report[key];
    });
    const report = {
      filename: "User Report.csv",
      data: data,
      // headers: [],
    };
    this.setState({ csvReport: report }, () => {
      done();
    });
  };

  render() {
    let iteminTable;
    if (this.props.loading) {
      iteminTable = <Loader loading={this.props.loading} />;
    } else {
      iteminTable = (
        <>
          <UserReportTableHead />
          {this.props.user_report && (
            <tbody>
              <UserReportTableBody {...this.props.user_report} />
            </tbody>
          )}
        </>
      );
    }
    return (
      <div>
        <SideBar />
        <div class="main-content" id="panel">
          <DashboardNavBar
            submenu="Report Summary"
            report={false}
            getInfo={this.handleRefresh}
            handleReport={this.getTotalTransaction}
            csvreport={this.state.csvReport}
            downloadCSV={this.exportCSV}
            iscsv={true}
          />
          <div class="container-fluid mt--6">
            <div class="row">
              <div class="col">
                <div class="card">
                  <div class="card-header">
                    <div className="row">
                      <div className="col-md-8">
                        <h3 class="mb-0">User Report</h3>
                      </div>
                      <div className="col-md-3">
                        <input
                          className="form-control"
                          name="year"
                          value={this.state.year}
                          onChange={this.handleChange}
                          placeholder="filter by year"
                        />
                      </div>
                      <div className="col-md-1">
                        <button
                          className="btn btn-info"
                          onClick={this.filterUserReport}>
                          Filter
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="table-responsive py-4">
                    <table class="table table-flush" id="datatable">
                      {iteminTable}
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: state.report.user_report.loading,
  user_report: state.report.user_report.usersreport,
  token: state.login.token,
});

export default connect(mapStateToProps, { getUserReport, filterReport })(
  UserReport
);
