import { headers } from "../api.config";

export const getKycDocument = async (token) => {
  const response = await fetch(
    `${process.env.REACT_APP_BASE_URL}/user/user_documents?per_page=25`,
    {
      headers: headers(token),
    }
  );
  if (response.ok) {
    const { data } = await response.json();
    return { data };
  }
};

export const ApproveDocument = async (data, url, token) => {
  const response = await fetch(
    `${process.env.REACT_APP_BASE_URL}/user/${url}/kyc_level/document`,
    {
      method: "POST",
      headers: headers(token),
      body: JSON.stringify(data),
    }
  );

  if (response.ok) {
    const { response_message } = await response.json();
    return { response_message };
  }
};

export const finalApproval = async (data, url, token) => {
  const response = await fetch(
    `${process.env.REACT_APP_BASE_URL}/user/${url}`,
    {
      method: "POST",
      headers: headers(token),
      body: JSON.stringify(data),
    }
  );

  if (response.ok) {
    const { response_message } = await response.json();
    return { response_message };
  }
};

export const filterDocumentByStatus = async (status, token) => {
  const response = await fetch(
    `${process.env.REACT_APP_BASE_URL}/user/user_virtual_card_trans`,
    {
      headers: headers(token),
      body: JSON.stringify({ filter: status }),
    }
  );

  if (response.ok) {
    const { data } = await response.json();
    return { data };
  }
};
