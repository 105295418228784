import React from "react";
import { Modal, DateRange } from "../../components/Accessories";

class ModalFilter extends React.Component {
  state = {
    reference: "",
    debited_ac: "",
    channel: "",
    transType: "",
    narration: "",
    transAmount: "",
    startDate: null,
    endDate: null,
  };

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  handleRange = (event, picker) => {
    let start = new Date(picker.startDate).toISOString();
    let end = new Date(picker.endDate).toISOString();

    start = start.substring(0, start.indexOf("T"));
    end = end.substring(0, end.indexOf("T"));
    this.setState({
      startDate: start,
      endDate: end,
    });
  };

  submit = () => {
    this.props.handleSubmit(this.state);
  };

  render() {
    return (
      <>
        <Modal
          display={this.props.display ? "block" : "none"}
          closeModal={this.props.closeModal}>
          <div className="body-grid">
            <div className="box box9">
              <label>Select Date Range</label>
              <DateRange handleRange={this.handleRange} />
            </div>
            <div className="box box1">
              <label>Enter Transaction Reference</label>
              <input
                name="reference"
                value={this.state.reference}
                onChange={this.handleChange}
                className="form-control"
                placeholder={`filter by transaction Reference`}
              />
            </div>
            <div className="box box2">
              <label>Enter Debited Account</label>
              <input
                name="debited_ac"
                onChange={this.handleChange}
                value={this.state.debited_ac}
                placeholder={`filter by debited Account`}
                className="form-control"
              />
            </div>
            <div className="box box1">
              <label>Enter Channel Id</label>
              <input
                name="channel"
                onChange={this.handleChange}
                value={this.state.channel}
                placeholder={`filter by channel Id`}
                className="form-control"
              />
            </div>

            <div className="box box2">
              <label>Enter Transaction Type</label>
              <input
                className="form-control"
                name="transType"
                onChange={this.handleChange}
                value={this.state.transType}
                placeholder={`filter by transaction Type`}
              />
            </div>

            <div className="box box1">
              <label>Enter Narration</label>
              <input
                className="form-control"
                name="narration"
                onChange={this.handleChange}
                value={this.state.narration}
                placeholder={`filter by narration`}
              />
            </div>
            <div className="box box2">
              <label>Enter Transaction Amount</label>
              <input
                className="form-control"
                name="transAmount"
                onChange={this.handleChange}
                value={this.state.transAmount}
                placeholder={`filter by transaction Amount`}
              />
            </div>
            <div className="box box9">
              <div className="text-center">
                <button
                  type="button"
                  class="btn btn-primary my-4"
                  onClick={this.submit}>
                  Filter
                </button>
              </div>
            </div>
          </div>
        </Modal>
      </>
    );
  }
}

export default ModalFilter;
