import React from "react";
import { connect } from "react-redux";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import Routes from "./Route";
import { Login } from "../pages/Login";
import {
  logOut,
  AppStart,
  getPermissionMod,
} from "../reduxStore/action/action.creator";

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      rest.token ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: "/",
            state: { from: props.location },
          }}
        />
      )
    }
  />
);

class AuthenticatedRoute extends React.Component {
  componentDidMount() {
    this.props.getPermissionMod();
  }

  render() {
    return (
      <Router>
        <Switch>
          <Route exact path="/" component={Login} />
          <PrivateRoute {...this.props} component={Routes} />
        </Switch>
      </Router>
    );
  }
}

const mapStateToProps = (state) => ({
  token: state.login.token,
  err: state.users.allusers.failed,
});
export default connect(mapStateToProps, { logOut, AppStart, getPermissionMod })(
  AuthenticatedRoute
);
