import {
  AUDIT_LOGS_SUCCESS,
  AUDIT_LOGS_FAILED
} from "../action/action.types";

import { combineReducers } from "redux";

const initialState = {
  auditLogsData: [],
  response_msg: "",
};

const auditLogsReducer = (state = {}, action) => {
  switch (action.type) {
    case "SENDING":
      return {
        ...state,
        loading: true,
        update_message: "",
      };
    case AUDIT_LOGS_SUCCESS:
      return {
        ...state,
        auditLogsData: action.payload.data,
        loading: false,
      };
    case AUDIT_LOGS_FAILED:
      return {
        ...state,
        failed: action.payload,
      };
    default:
      return state;
  }
};

export const auditLogs = combineReducers({
  auditLogs: auditLogsReducer,
});