import React from "react";
import "../../../morestyles.css";
import {
  TransactionReportTableHead,
  TransactionReportTableBody,
} from "../../../components/Rows/ReportTable";
import {
  getTransactionByInstitute,
  getInstitutions,
  filterByInstitution,
} from "../../../reduxStore/action/action.creator";
import { connect } from "react-redux";
import { Loader } from "../../../components/Accessories";
import { CSVLink } from "react-csv";
import ReportBar from "./TransactionReport";

class TransactionByInstituteReport extends React.Component {
  state = {
    year: "",
    institution: "",
    csvReport: {
      data: [],
      filename: "some.csv",
    },
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevState.institution != this.state.institution) {
      this.props.filterByInstitution(
        `get_institution_summary?institution=${this.state.institution}`,
        this.props.token
      );
    }
  }

  componentDidMount() {
    this.props.getInstitutions(this.props.token);
    this.props.getTransactionByInstitute(
      "get_institution_summary",
      this.props.token
    );
  }

  handleInstituteChange = (event) => {
    this.setState({
      institution: event.target.value,
    });
  };

  exportCSV = (event, done) => {
    const data = this.reportByMonth();
    const filename = "transaction report.csv";

    const report = {
      filename: filename,
      data: data,
      // headers: [],
    };
    this.setState({ csvReport: report }, () => {
      done();
    });
  };

  reportByMonth = () => {
    const data = Object.keys(this.props.transactionreport).map((key, index) => {
      const csvData = {};

      csvData.Month = key;
      const report = this.props.transactionreport[key];
      csvData.Completed = `${report[5].count} / ${report[5].total}`;
      csvData.Paid = `${report[4].count} / ${report[4].total}`;
      csvData.Reversed = `${report[3].count} / ${report[3].count}`;
      csvData.Pending = `${report[0].count} / ${report[0].total}`;
      csvData.Progress = `${report[2].count} / ${report[2].total}`;
      csvData.Failed = `${report[1].count} / ${report[1].total}`;
      return csvData;
    });
    return data;
  };

  handleRefresh = () => {
    this.props.getInstitutions(this.props.token);
    this.props.getTransactionByInstitute(
      "get_institution_summary",
      this.props.token
    );
  };

  render() {
    let iteminTable;

    if (this.props.loading) {
      iteminTable = <Loader loading={this.props.loading} />;
    } else {
      iteminTable = (
        <>
          <TransactionReportTableHead />
          <tbody>
            {this.props.transactionreport &&
              this.props.transactionreport.map((content) => (
                <TransactionReportTableBody {...content} />
              ))}
          </tbody>
        </>
      );
    }
    return (
      <div>
        <ReportBar
          active="transactionbyInstitute"
          handleRefresh={this.handleRefresh}>
          <div class="card-header">
            <div className="row">
              <div className="col-md-8">
                <h3 class="mb-0">
                  {this.state.institution != "" &&
                    `Transaction Report By ${this.state.institution}`}
                </h3>
              </div>
              <div className="col-md-4">
                <div className="row">
                  <div className="col-md-8">
                    <select
                      name="institute"
                      value={this.state.institution}
                      onChange={this.handleInstituteChange}
                      className="form-control">
                      {this.props.institute &&
                        this.props.institute.map((content) => (
                          <option value={content}>{content}</option>
                        ))}
                    </select>
                  </div>
                  <div className="col-md-4">
                    <CSVLink
                      {...this.state.csvReport}
                      asyncOnClick={true}
                      className="btn btn-sm btn-neutral"
                      onClick={this.exportCSV}>
                      <i className="fa fa-export"></i> Export CSV
                    </CSVLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="table-responsive py-4">
            <table class="table table-flush" id="datatable">
              {iteminTable}
            </table>
          </div>
        </ReportBar>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  transactionreport: state.report.transaction_report.transactionreport,
  loading: state.report.transaction_report.loading,
  institute: state.alltransactions.application_institute.institute,
  token: state.login.token,
});

export default connect(mapStateToProps, {
  getTransactionByInstitute,
  getInstitutions,
  filterByInstitution,
})(TransactionByInstituteReport);
