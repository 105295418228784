import React from "react";
import "../morestyles.css";
import { Link } from "react-router-dom";
import { Badge } from "react-bootstrap";

export const dateFormatter = (props) => {
  const newDate = new Date(props);
  return newDate.toDateString();
};

export const timeFormatter = (props) => {
  const newTime = new Date(props);
  return newTime.toLocaleTimeString();
};

export const currencyFormatter = (props) => {
  const currency = props.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return currency;
};

export const decimalFormatter = (props) => {
  const amount = props.toFixed(2);
  return amount;
};

export const statusButton = (props) => {
  let buttonColor;
  let status;
  switch (props) {
    case "TS_COMPLETED":
      status = "COMPLETED";
      buttonColor = "badge badge-success txn-status";
      break;
    case "TS_PAID":
      status = "PAID";
      buttonColor = "badge badge-primary txn-status";
      break;
    case "TS_PENDING":
      status = "PENDING";
      buttonColor = "badge badge-warning txn-status";
      break;
    case "TS_FAILED":
      status = "FAILED";
      buttonColor = "badge badge-danger txn-status";
      break;
    default:
      buttonColor = "badge badge-info txn-status";
      break;
  }
  return { status, buttonColor };
};

// switch (props) {
//   case "TS_COMPLETED":
//     status = "COMPLETED"
//     badgeColor = "badge badge-success";
//     break;
//   case "TS_PAID":
//     status = "PAID"
//     badgeColor = "badge badge-primary";
//     break;
//   case "TS_PENDING":
//     status = "PENDING"
//     badgeColor = "badge badge-warning";
//     break;
//   case "TS_FAILED":
//     status = "FAILED"
//     badgeColor = "badge badge-danger";
//     break;
//   default:
//     badgeColor = "badge badge-info";
//     break;
// }

export const loanStatus = (props) => {
  let buttonColor, status_id, action, badgeColor;
  switch (props) {
    case 1:
      status_id = "Pending";
      action = "Approve";
      badgeColor = "badge badge-warning txn-status";
      buttonColor = "btn btn-primary txn-status";
      break;
    case 2:
      status_id = "Approved";
      action = "Disburse";
      badgeColor = "badge badge-primary txn-status";
      buttonColor = "btn btn-info txn-status";
      break;
    case 3:
      status_id = "Authorized";
      buttonColor = "badge badge-success txn-status";
      break;
    case 4:
      status_id = "Rejected";
      buttonColor = "badge badge-danger txn-status";
      break;
    case 5:
      status_id = "Running";
      action = "Reject";
      badgeColor = "badge badge-info txn-status";
      buttonColor = "btn btn-danger txn-status";
      break;
    case 6:
      status_id = "Paid";
      buttonColor = "badge badge-secondary txn-status";
      break;
    case 7:
      status_id = "Overdue";
      buttonColor = "badge badge-danger txn-status";
      break;
    case 8:
      status_id = "Paid Overdue";
      buttonColor = "badge badge-dark txn-status";
      break;
    default:
      break;
  }
  return { status_id, buttonColor, action, badgeColor };
};
export const statusBadge = (props) => {
  let badgeColor;
  let status;
  switch (props) {
    case "TS_COMPLETED":
      status = "COMPLETED";
      badgeColor = "badge badge-success";
      break;
    case "TS_PAID":
      status = "PAID";
      badgeColor = "badge badge-primary";
      break;
    case "TS_PENDING":
      status = "PENDING";
      badgeColor = "badge badge-warning";
      break;
    case "TS_FAILED":
      status = "FAILED";
      badgeColor = "badge badge-danger";
      break;
    default:
      badgeColor = "badge badge-info";
      break;
  }
  return { status, badgeColor };
};
