import { headers } from "./api.config";

export const getUserCount = async (token) => {
  const date = new Date().toISOString().substring(0, 10);
  const response = await fetch(
    `${process.env.REACT_APP_BASE_URL}/user/all_count_for_users/2020-07-01/${date}`,
    {
      headers: headers(token),
    }
  );
  if (response.ok) {
    const { data } = await response.json();
    return data;
  }
};

export const monthlyTransaction = async (token) => {
  const response = await fetch(
    `${process.env.REACT_APP_BASE_URL}/transaction/total_monthly_txns`,
    {
      headers: headers(token),
    }
  );

  if (response.ok) {
    const { data } = await response.json();
    return data;
  }
};

// export const activeUser = async (token) => {
//   const response = await fetch(
//     `${process.env.REACT_APP_BASE_URL}/user/number-of-active/users`,
//     {
//       headers: headers(token),
//     }
//   );

//   if (response.ok) {
//     const { data } = await response.json();
//     return data;
//   }
// };
