import React from "react";
import SideBar from "../../../components/SideBar";
import DashboardNavBar from "../../../components/DashboardNavbar";
import "../../../morestyles.css";
import { Loader, Icon, CardBodyHolder } from "../../../components/Accessories";
import { Tabs, Tab } from "react-bootstrap";
import { Link } from "react-router-dom";

class TransactionReport extends React.Component {
  state = {
    key: "",
  };

  getActiveState = (k) => {
    this.setState({
      key: k,
    });
  };

  render() {
    return (
      <div>
        <SideBar />
        <div class="main-content" id="panel">
          <DashboardNavBar
            submenu="Transaction Report Summary"
            report={false}
            getInfo={this.props.handleRefresh}
            handleReport={this.getTotalTransaction}
          />
          <CardBodyHolder>
            <Tabs
              id="controlled-tab-example"
              activeKey={this.props.active}
              onSelect={this.getActiveState}>
              <Tab
                eventKey="transactionbyDate"
                title={
                  <Link to={`/report/transaction-report/transaction-month`}>
                    <Icon className="" title="Transaction By Month" />
                  </Link>
                }
                tabClassName="mTab kycTab">
                {this.props.children}
              </Tab>
              <Tab
                eventKey="transactionbyInstitute"
                title={
                  <Link to={`/report/transaction-report/transaction-institute`}>
                    <Icon
                      className=""
                      title="Transaction By Institute and Month"
                    />
                  </Link>
                }
                tabClassName="mTab kycTab">
                {this.props.children}
              </Tab>
              <Tab
                eventKey="instituteStatus"
                title={
                  <Link to={`/report/transaction-report/institute-report`}>
                    <Icon className="" title="Institution Status" />
                  </Link>
                }
                tabClassName="mTab kycTab">
                {this.props.children}
              </Tab>
              <Tab
                eventKey="serviceStatus"
                title={
                  <Link to={`/report/transaction-report/service-report`}>
                    <Icon className="" title="Service Status" />
                  </Link>
                }
                tabClassName="mTab kycTab">
                {this.props.children}
              </Tab>
            </Tabs>
          </CardBodyHolder>
        </div>
      </div>
    );
  }
}

export default TransactionReport;
