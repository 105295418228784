import React from "react";
import { currencyFormatter, decimalFormatter } from "../Row";

const checkDate = (date) => {
  const mDate = new Date(date).toLocaleString("default", {
    month: "long",
  });

  return mDate;
};
const getStatus = (props) => {
  const data = props.aggregateData.sort((a, b) => {
    let status1 = a._id.toLowerCase(),
      status2 = b._id.toLowerCase();

    if (status1 < status2) {
      return -1;
    }
    if (status1 > status2) {
      return 1;
    }

    return 0;
  });

  return data;
};

export const UserReportTableHead = () => (
  <thead class="thead-light">
    <tr>
      <th>Months</th>
      <th>Total Referrals</th>
      <th>Total Registration</th>
      <th>Total Verified Users</th>
      <th>Total Unverfied Users</th>
      <th>Registered by BVN</th>
      <th>Registered by no BVN</th>
      <th>Active Users</th>
      <th>Registration on Web</th>
      <th>Registration on Mobile</th>
    </tr>
  </thead>
);

export const UserReportTableBody = (props) => (
  <>
    {Object.keys(props).map((key) => (
      <tr>
        <td>{`${key.substring(0, 1).toUpperCase()}${key.substring(
          1,
          key.length
        )}`}</td>
        <td>{props[key].user_refferal}</td>
        <td>{props[key].total_user}</td>
        <td>{props[key].verify_user}</td>
        <td>{props[key].un_verify_user}</td>
        <td>{props[key].user_with_bvn}</td>
        <td>{props[key].user_without_bvn}</td>
        <td>{props[key].active_user}</td>
        <td></td>
        <td></td>
      </tr>
    ))}
  </>
);

export const TransactionReportTableHead = (props) => (
  <thead class="thead-light">
    <tr>
      <th>Months</th>
      <th>Completed (count/total)</th>
      <th>Paid (count/total)</th>
      <th>Reversed (count/total)</th>
      <th>Pending (count/total)</th>
      <th>In Progress (count/total)</th>
      <th>Failed (count/total)</th>
    </tr>
  </thead>
);

const checkReportNumber = (info) => {
  let result;
  if (info.length <= 0) {
    result = 0;
  } else {
    result = info;
  }
};

export const TransactionReportTableBody = (props) => {
  const prop = getStatus(props);
  return (
    <>
      <tr>
        <td>{checkDate(props.monthYear)}</td>
        <>
          <td>
            {props.aggregateData.length > 0
              ? `${prop[0].count} / ${currencyFormatter(
                  getStatus(props)[0].total
                )}`
              : `0/0`}
          </td>
          <td>
            {props.aggregateData.length > 0 && prop[3]
              ? `${prop[3].count} / ${currencyFormatter(
                  getStatus(props)[3].total
                )}`
              : `0/0`}
          </td>
          <td>
            {props.aggregateData.length > 0 && prop[5]
              ? `${prop[5].count} / ${currencyFormatter(
                  getStatus(props)[5].total
                )}`
              : `0/0`}
          </td>
          <td>
            {props.aggregateData.length > 0 && prop[4]
              ? `${prop[4].count} / ${currencyFormatter(
                  getStatus(props)[4].total
                )}`
              : `0/0`}
          </td>
          <td>
            {props.aggregateData.length > 0 && prop[2]
              ? `${prop[2].count} / ${currencyFormatter(
                  getStatus(props)[2].total
                )}`
              : `0/0`}
          </td>
          <td>
            {props.aggregateData.length > 0 && prop[1]
              ? `${prop[1].count} / ${currencyFormatter(
                  getStatus(props)[1].total
                )}`
              : `0/0`}
          </td>
        </>
      </tr>
    </>
  );
};

export const InstitutionTableHead = (props) => (
  <thead class="thead-light">
    <tr>
      <th>{props.title == "serviceStatus" ? "Services" : "Institutions"}</th>
      <th>Completed (count/total)</th>
      <th>Paid (count/total)</th>
      <th>Reversed (count/total)</th>
      <th>Pending (count/total)</th>
      <th>In Progress (count/total)</th>
      <th>Failed (count/total)</th>
    </tr>
  </thead>
);

const reArrange = (data) => {
  const service_name = data.service.map((content) => {
    if (content.serviceCode == data.serviceCode) {
      return content.serviceName;
    }
  });
  return service_name;
};
export const InstitutionRow = (props) => {
  const serviceName = reArrange(props);
  const prop = getStatus(props);
  return (
    <>
      {/* {alert(JSON.stringify(props))} */}
      <tr>
        <td>{serviceName ? serviceName : "wassa"}</td>
        <td>
          {props.aggregateData.length > 0 && prop[0]
            ? `${prop[0].count} / ${currencyFormatter(
                getStatus(props)[0].total
              )}`
            : `0/0`}
        </td>
        <td>
          {props.aggregateData.length > 0 && prop[3]
            ? `${prop[3].count} / ${currencyFormatter(
                getStatus(props)[3].total
              )}`
            : `0/0`}
        </td>
        <td>
          {props.aggregateData.length > 0 && prop[5]
            ? `${prop[5].count} / ${currencyFormatter(
                getStatus(props)[5].total
              )}`
            : `0/0`}
        </td>
        <td>
          {props.aggregateData.length > 0 && prop[4]
            ? `${prop[4].count} / ${currencyFormatter(
                getStatus(props)[4].total
              )}`
            : `0/0`}
        </td>
        <td>
          {props.aggregateData.length > 0 && prop[2]
            ? `${prop[2].count} / ${currencyFormatter(
                getStatus(props)[2].total
              )}`
            : `0/0`}
        </td>
        <td>
          {props.aggregateData.length > 0 && prop[1]
            ? `${prop[1].count} / ${currencyFormatter(
                getStatus(props)[1].total
              )}`
            : `0/0`}
        </td>
        {/* <td>
          {item.data[3].count} /{" "}
          {currencyFormatter(decimalFormatter(parseFloat(item.data[3].total)))}
        </td>
        <td>
          {item.data[5].count} /{" "}
          {currencyFormatter(decimalFormatter(parseFloat(item.data[5].total)))}
        </td>
        <td>
          {item.data[4].count} /{" "}
          {currencyFormatter(decimalFormatter(parseFloat(item.data[4].total)))}
        </td>
        <td>
          {item.data[2].count} /{" "}
          {currencyFormatter(decimalFormatter(parseFloat(item.data[2].total)))}
        </td>
        <td>
          {item.data[1].count} /{" "}
          {currencyFormatter(decimalFormatter(parseFloat(item.data[1].total)))}
        </td> */}
      </tr>
    </>
  );
};

// export const InstitutionList = (props) => {
//   const useInstitute = props.report.reduce((obj, item) => {
//     const institute = item.institution || item.transactionService;

//     obj[institute] = [...(obj[institute] || []), item];

//     return obj;
//   }, {});

//   const institutionName = Object.keys(useInstitute)
//     .sort()
//     .map((key, index) => ({
//       title:
//         props.service[index].serviceCode == key
//           ? props.service[index].serviceName
//           : key,
//       data: useInstitute[key].sort((a, b) => {
//         let status1 = a.status.toLowerCase(),
//           status2 = b.status.toLowerCase();

//         if (status1 < status2) {
//           return -1;
//         }
//         if (status1 > status2) {
//           return 1;
//         }

//         return 0;
//       }),
//     }));
//   return institutionName.map((content) => <InstitutionRow item={content} />);
// };
