import {
  FEE_PROFILE_FAILED,
  FEE_PROFILE_SUCCESS,
  SET_FEE_PROFILE_FAILED,
  SET_FEE_PROFILE_SUCCESS,
} from "../action/action.types";
import { combineReducers } from "redux";

const initialState = {
  feeprofile: [],
  total: 0,
};

const feesReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SENDING":
      return {
        ...state,
        loading: true,
      };
    case FEE_PROFILE_SUCCESS:
      return {
        ...state,
        feeprofile: action.payload.mFeeProfiles,
        total: action.payload.allFeeProfiles,
        loading: false,
      };
    case FEE_PROFILE_FAILED:
      return {
        ...state,
        feeprofile: [],
        failed: action.payload,
      };
    default:
      return state;
  }
};

const setfeeProfile = (state = {}, action) => {
  switch (action.type) {
    case "ISLOADING":
      return {
        ...state,
        loading: true,
      };
    case SET_FEE_PROFILE_SUCCESS:
      return {
        ...state,
        successMsg: true,
        loading: false,
      };
    case FEE_PROFILE_SUCCESS:
      return {
        ...state,
        successMsg: false,
        loading: false,
      };
    case SET_FEE_PROFILE_FAILED:
      return {
        ...state,
        failed: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};

export const initialFeeReducer = combineReducers({
  allfees: feesReducer,
  setfees: setfeeProfile,
});
