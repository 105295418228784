import React from "react";
import SideBar from "../../components/SideBar";
import DashboardNavBar from "../../components/DashboardNavbar";
import { Link } from "react-router-dom";
import { monthlyTransaction, getUserCount } from "../../API";
import { currencyFormatter } from "../../components/Row";
import { connect } from "react-redux";

class Dashboard extends React.Component {
  state = {};

  componentDidMount() {
    this.getMonthlyTransaction();
    this.getTotalUsers();
  }

  getTotalUsers = async () => {
    try {
      const res = await getUserCount(this.props.token);
      this.setState({
        ...this.state,
        total_users: res,
      });
    } catch (error) {}
  };

  // getActiveUsers = async () => {
  //   try {
  //     const res = await activeUser(this.props.token);
  //     this.setState({
  //       ...this.state,
  //       active_user: res,
  //     });
  //   } catch (error) {}
  // };

  getMonthlyTransaction = async () => {
    try {
      const res = await monthlyTransaction(this.props.token);
      this.setState({
        ...this.state,
        monthly_trans: res.totalTransactions,
        trans_count: res.summedAmount,
      });
    } catch (error) {}
  };

  render() {
    return (
      <div>
        <SideBar />
        <div className="main-content" id="panel">
          {/* <DashboardNavBar /> */}
          <div class="header bg-primary pb-6">
            <div class="container-fluid">
              <div class="header-body">
                <div class="row align-items-center py-4">
                  <div class="col-lg-6 col-7">
                    <h6 class="h2 text-white d-inline-block mb-0">Default</h6>
                    <nav
                      aria-label="breadcrumb"
                      class="d-none d-md-inline-block ml-md-4">
                      <ol class="breadcrumb breadcrumb-links breadcrumb-dark">
                        <li class="breadcrumb-item">
                          <a href="#">
                            <i class="fas fa-home"></i>
                          </a>
                        </li>
                        <li class="breadcrumb-item">
                          <a href="#">Dashboards</a>
                        </li>
                        <li class="breadcrumb-item active" aria-current="page">
                          Default
                        </li>
                      </ol>
                    </nav>
                  </div>
                  <div class="col-lg-6 col-5 text-right">
                    <a href="#" class="btn btn-sm btn-neutral">
                      New
                    </a>
                    <a href="#" class="btn btn-sm btn-neutral">
                      Filters
                    </a>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-3 col-md-6">
                    <div class="card card-stats">
                      <div class="card-body">
                        <div class="row">
                          <div class="col">
                            <h5 class="card-title text-uppercase text-muted mb-0">
                              Number of Users
                            </h5>
                            <span class="h2 font-weight-bold mb-0">
                              {this.state.total_users &&
                                this.state.total_users.users_total}
                            </span>
                          </div>
                          <div class="col-auto">
                            <div class="icon icon-shape bg-gradient-red text-white rounded-circle shadow">
                              <i class="ni ni-active-40"></i>
                            </div>
                          </div>
                        </div>
                        <p class="mt-3 mb-0 text-sm">
                          <span class="text-success mr-2">
                            <i class="fa fa-arrow-up"></i> {(1 / 10) * 100}%
                          </span>
                          <span class="text-nowrap">Since last month</span>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-3 col-md-6">
                    <div class="card card-stats">
                      <div class="card-body">
                        <div class="row">
                          <div class="col">
                            <h5 class="card-title text-uppercase text-muted mb-0">
                              Active Users
                            </h5>
                            <span class="h2 font-weight-bold mb-0">
                              {this.state.total_users &&
                                this.state.total_users.active_user}
                            </span>
                          </div>
                          <div class="col-auto">
                            <div class="icon icon-shape bg-gradient-orange text-white rounded-circle shadow">
                              <i class="ni ni-chart-pie-35"></i>
                            </div>
                          </div>
                        </div>
                        <p class="mt-3 mb-0 text-sm">
                          <span class="text-success mr-2">
                            <i class="fa fa-arrow-up"></i> 3.48%
                          </span>
                          <span class="text-nowrap">Since last month</span>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-3 col-md-6">
                    <div class="card card-stats">
                      <div class="card-body">
                        <div class="row">
                          <div class="col">
                            <h5 class="card-title text-uppercase text-muted mb-0">
                              Total Transaction
                            </h5>
                            <span class="h2 font-weight-bold mb-0">
                              {currencyFormatter(
                                parseInt(this.state.trans_count)
                              )}
                            </span>
                          </div>
                          <div class="col-auto">
                            <div class="icon icon-shape bg-gradient-green text-white rounded-circle shadow">
                              <i class="ni ni-money-coins"></i>
                            </div>
                          </div>
                        </div>
                        <p class="mt-3 mb-0 text-sm">
                          <span class="text-success mr-2">
                            <i class="fa fa-arrow-up"></i> 3.48%
                          </span>
                          <span class="text-nowrap">Since last month</span>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-3 col-md-6">
                    <div class="card card-stats">
                      <div class="card-body">
                        <div class="row">
                          <div class="col">
                            <h5 class="card-title text-uppercase text-muted mb-0">
                              Transaction Count
                            </h5>
                            <span class="h2 font-weight-bold mb-0">
                              {this.state.monthly_trans}
                            </span>
                          </div>
                          <div class="col-auto">
                            <div class="icon icon-shape bg-gradient-info text-white rounded-circle shadow">
                              <i class="ni ni-chart-bar-32"></i>
                            </div>
                          </div>
                        </div>
                        <p class="mt-3 mb-0 text-sm">
                          <span class="text-success mr-2">
                            <i class="fa fa-arrow-up"></i> 3.48%
                          </span>
                          <span class="text-nowrap">Since last month</span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* page content */}
          <div class="container-fluid mt--6">
            <div class="row">
              <div class="col-xl-8">
                <div class="card bg-default">
                  <div class="card-header bg-transparent">
                    <div class="row align-items-center">
                      <div class="col">
                        <h6 class="text-light text-uppercase ls-1 mb-1">
                          Overview
                        </h6>
                        <h5 class="h3 text-white mb-0">Sales value</h5>
                      </div>
                      <div class="col">
                        <ul class="nav nav-pills justify-content-end">
                          <li
                            class="nav-item mr-2 mr-md-0"
                            data-toggle="chart"
                            data-target="#chart-sales-dark"
                            data-update='{"data":{"datasets":[{"data":[0, 20, 10, 30, 15, 40, 20, 60, 60]}]}}'
                            data-prefix="$"
                            data-suffix="k">
                            <a
                              href="#"
                              class="nav-link py-2 px-3 active"
                              data-toggle="tab">
                              <span class="d-none d-md-block">Month</span>
                              <span class="d-md-none">M</span>
                            </a>
                          </li>
                          <li
                            class="nav-item"
                            data-toggle="chart"
                            data-target="#chart-sales-dark"
                            data-update='{"data":{"datasets":[{"data":[0, 20, 5, 25, 10, 30, 15, 40, 40]}]}}'
                            data-prefix="$"
                            data-suffix="k">
                            <a
                              href="#"
                              class="nav-link py-2 px-3"
                              data-toggle="tab">
                              <span class="d-none d-md-block">Week</span>
                              <span class="d-md-none">W</span>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="card-body">
                    <div class="chart">
                      <canvas
                        id="chart-sales-dark"
                        class="chart-canvas"></canvas>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-xl-4">
                <div class="card">
                  <div class="card-header bg-transparent">
                    <div class="row align-items-center">
                      <div class="col">
                        <h6 class="text-uppercase text-muted ls-1 mb-1">
                          Performance
                        </h6>
                        <h5 class="h3 mb-0">Total orders</h5>
                      </div>
                    </div>
                  </div>
                  <div class="card-body">
                    <div class="chart">
                      <canvas id="chart-bars" class="chart-canvas"></canvas>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <footer class="footer pt-0">
              <div class="row align-items-center justify-content-lg-between">
                <div class="col-lg-6">
                  <div class="copyright text-center  text-lg-left  text-muted">
                    &copy; 2020{" "}
                    <a href="#" class="font-weight-bold ml-1" target="_blank">
                      Getri Pay
                    </a>
                  </div>
                </div>
                <div class="col-lg-6">
                  <ul class="nav nav-footer justify-content-center justify-content-lg-end">
                    <li class="nav-item">
                      <a href="#" class="nav-link" target="_blank">
                        Getri Pay
                      </a>
                    </li>
                    <li class="nav-item">
                      <a href="#" class="nav-link" target="_blank">
                        About Us
                      </a>
                    </li>
                    <li class="nav-item">
                      <a href="#" class="nav-link" target="_blank">
                        Blog
                      </a>
                    </li>
                    <li class="nav-item">
                      <a href="#" class="nav-link" target="_blank">
                        License
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </footer>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  token: state.login.token,
});
export default connect(mapStateToProps, {})(Dashboard);
