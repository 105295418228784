import React from "react";
import { connect } from "react-redux";
import SideBar from "../../components/SideBar";
import DashboardNavBar from "../../components/DashboardNavbar";
import {
  TransactionList,
  TransactionFee,
  TransactionCommission,
} from "../../components/Rows";
import { Modal } from "../../components/Accessories";
import { Link } from "react-router-dom";
import { detailsInfo } from "../../reduxStore/action/action.creator";

class TransactionDetails extends React.Component {
  state = {};

  componentDidMount() {
    // const { state } = this.props.history.location;

    let index = this.props.history.location.pathname;
    index = index.substring(index.indexOf("s/") + 2, index.indexOf("/d"));
    this.props.detailsInfo(
      `transaction/get_tnx/${index}`,
      "transaction",
      this.props.token
    );
  }

  closeModal = () => {
    this.setState({
      display: false,
    });
  };

  showModal = (e) => {
    e.preventDefault();
    this.setState({
      display: true,
    });
  };

  goBack = () => {
    this.props.history.goBack();
  };
  render() {
    let itemInCard;
    const params = this.props.location;
    // let result = this.props.transdetails.data;

    if (this.props.loading) {
      itemInCard = "loading";
    } else {
      itemInCard = (
        <div class="row" style={{ display: "flex", borderRadius: "2em" }}>
          <div class="col-7 userDetailsCol1">
            <div
              style={{
                width: "100%",
                overflowX: "scroll",
              }}>
              {this.props.transdetails && (
                <TransactionList {...this.props.transdetails.data} />
              )}
            </div>
          </div>
          <div class="col-5 userDetailsCol2">
            <div class="m-3">
              <span style={{ fontWeight: "bold", fontSize: "13px" }}>
                <i class="fas fa-laptop"> &nbsp; </i> Device Information
              </span>
              <table
                class="table table-borderless mt-3"
                style={{ padding: "0px", textAlign: "left" }}>
                <thead>
                  <tr style={{ fontWeight: "bold", color: "lightgray" }}>
                    <th scope="col" style={{ width: "70%" }}>
                      IP ADDRESS
                    </th>
                    <th
                      scope="col"
                      style={{ width: "30%", textAlign: "right" }}>
                      LOCATION
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td scope="col" style={{ paddingTop: "0px" }}>
                      {this.props.transdetails &&
                        this.props.transdetails.data.transaction.iP}
                    </td>
                    <td
                      scope="col"
                      style={{ textAlign: "right", paddingTop: "0px" }}>
                      {this.props.transdetails &&
                        this.props.transdetails.data.transaction.location}
                    </td>
                  </tr>
                </tbody>
              </table>
              <hr style={{ marginTop: "0rem", marginBottom: "0rem" }} />
              <div>
                <span style={{ fontWeight: "bold", fontSize: "13px" }}>
                  <i class="fas fa-history"> &nbsp; </i> Reversed Transaction
                </span>
                <p
                  class="pt-3 ml-3"
                  style={{ fontSize: "13px", fontWeight: "bold" }}>
                  N/A
                </p>
              </div>

              <hr style={{ marginTop: "0rem", marginBottom: "0rem" }} />

              <div>
                {/* fee transaction here */}
                {this.props.transdetails && (
                  <TransactionFee {...this.props.transdetails.data} />
                )}

                {/* commission config here */}
                {this.props.transdetails && (
                  <TransactionCommission {...this.props.transdetails.data} />
                )}
              </div>
            </div>
          </div>
        </div>
      );
    }
    return (
      <>
        <div>
          <SideBar />
          <div className="main-content" id="panel">
            <DashboardNavBar settings="false" />
            <div class="header pb-6" style={{ backgroundColor: "#0038ba" }}>
              <div class="container-fluid">
                <div class="header-body">
                  <div class="row align-items-center py-4">
                    <div className="col-lg-6 col-7">
                      <span onClick={this.goBack} style={{ cursor: "pointer" }}>
                        <span
                          style={{
                            padding: "0 .5em 0 0",
                            textAlign: "center",
                          }}>
                          <i
                            class="fas fa-chevron-left"
                            style={{
                              color: "#fff",
                              padding: "0.3em .5em",
                              border: "2px solid #fff",
                              borderRadius: "50%",
                              fontSize: "12px",
                            }}></i>
                        </span>
                        <span style={{ color: "#fff" }}>Back</span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="container-fluid mt--5">{itemInCard}</div>
          </div>
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  transdetails: state.details.detailinfo,
  loading: state.details.loading,
  token: state.login.token,
});
export default connect(mapStateToProps, { detailsInfo })(TransactionDetails);
